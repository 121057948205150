import styled from 'styled-components';
import cn from 'classnames';
import { useState, ChangeEvent, useEffect } from 'react';

import { Add, Remove, TaskAlt } from '@mui/icons-material';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { useHistory } from 'react-router-dom';
import { PurchaseUser } from '../../containers/purchase';
import tokenLib from '../../library/token.lib';

function Shopping({
  user,
  purchase,
  orderList,
  setOrderList,
  handleDelete,
}: {
  user: any;
  purchase: any;
  orderList: any[];
  setOrderList: (state: any[]) => void;
  handleDelete: (list: any) => void;
}) {
  const handleCount = (detail: any, product: any, type: 'plus' | 'minus') => {
    let total_price = 0;

    const count =
      type === 'plus'
        ? parseFloat(detail.count) + 1
        : parseFloat(detail.count) - 1;

    if (!count) return;

    const size = product.size_list.find(
      (s: any) => s.size === detail.size && s.measurement === detail.measurement
    );

    const guam_discount_price =
      ((parseFloat(product.guam_original_price) +
        parseFloat(size.guam_quantity_price)) *
        parseFloat(size.guam_discount_percent)) /
      100;

    const saipan_discount_price =
      ((parseFloat(product.saipan_original_price) +
        parseFloat(size.saipan_quantity_price)) *
        parseFloat(size.saipan_discount_percent)) /
      100;

    if (tokenLib.getCountry() === 'guam') {
      switch (detail.count_type) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.guam_original_price) +
                  parseFloat(size.guam_quantity_price) -
                  guam_discount_price) *
                count
              : (parseFloat(product.guam_original_price) +
                  parseFloat(size.guam_quantity_price)) *
                count;
          break;

        case 'box':
          total_price =
            (parseFloat(product.guam_box_price) +
              parseFloat(size.guam_box_price)) *
            count;
          break;

        default:
          break;
      }
    } else {
      switch (detail.count_type) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.saipan_original_price) +
                  parseFloat(size.saipan_quantity_price) -
                  saipan_discount_price) *
                count
              : (parseFloat(product.saipan_original_price) +
                  parseFloat(size.saipan_quantity_price)) *
                count;
          break;

        case 'box':
          total_price =
            (parseFloat(product.saipan_box_price) +
              parseFloat(size.saipan_box_price)) *
            count;
          break;

        default:
          break;
      }
    }

    setOrderList(
      orderList.map((data: any) => {
        if (data.id === detail.id)
          return {
            ...data,
            count,
            total_price,
          };

        return data;
      })
    );
  };

  const handleD = (id: number) => {
    let list: any[] = [];

    for (const order of orderList) {
      if (order.id === id) {
        list = [
          ...list,
          {
            ...order,
            delete: true,
          },
        ];

        continue;
      }

      list = [...list, order];
    }

    handleDelete(list);
  };

  const handlePrice = (order: any) => {
    let total_price = 0;

    let product: any;

    for (const p of purchase.product_list) {
      if (p.id === order.product_id) product = p;
    }

    const size = product.size_list.find(
      (s: any) => s.size === order.size && s.measurement === order.measurement
    );

    const guam_discount_price =
      ((parseFloat(product.guam_original_price) +
        parseFloat(size.guam_quantity_price)) *
        parseFloat(size.guam_discount_percent)) /
      100;

    const saipan_discount_price =
      ((parseFloat(product.saipan_original_price) +
        parseFloat(size.saipan_quantity_price)) *
        parseFloat(size.saipan_discount_percent)) /
      100;

    if (tokenLib.getCountry() === 'guam') {
      switch (order.count_type) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.guam_original_price) +
                  parseFloat(size.guam_quantity_price) -
                  guam_discount_price) *
                1
              : (parseFloat(product.guam_original_price) +
                  parseFloat(size.guam_quantity_price)) *
                1;
          break;

        default:
          break;
      }
    } else {
      switch (order.count_type) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.saipan_original_price) +
                  parseFloat(size.saipan_quantity_price) -
                  saipan_discount_price) *
                1
              : (parseFloat(product.saipan_original_price) +
                  parseFloat(size.saipan_quantity_price)) *
                1;
          break;

        default:
          break;
      }
    }

    return total_price;
  };

  const handleTotal = (type: string = 'none') => {
    let price = 0;

    orderList.forEach((order) => (price += parseFloat(order.total_price)));

    switch (type) {
      case 'special': // 10%
        return price - Math.floor((price * 10) / 100);

      case 'wholesale': // 40%
        return price - Math.floor((price * 40) / 100);

      case 'salon': // 50%
        return price - Math.floor((price * 50) / 100);

      default:
        return price;
    }
  };

  const handleDiscount = (data: any, product: any) => {
    const size = product.size_list.find(
      (s: any) => s.size === data.size && s.measurement === data.measurement
    );

    if (
      product.type === 'sale' &&
      tokenLib.getCountry() === 'guam' &&
      size.guam_discount_percent !== '0'
    ) {
      return ` [${size.guam_discount_percent}% off]`;
    } else if (
      product.type === 'sale' &&
      tokenLib.getCountry() === 'saipan' &&
      size.saipan_discount_percent !== '0'
    ) {
      return ` [$${size.saipan_discount_percent}% off]`;
    } else {
      return '';
    }
  };

  useEffect(() => {
    let array: any[] = [];

    for (const order of orderList) {
      let total_price = 0;

      let product: any;

      for (const p of purchase.product_list) {
        if (p.id === order.product_id) product = p;
      }

      if (!product) continue;

      const size = product.size_list.find(
        (s: any) => s.size === order.size && s.measurement === order.measurement
      );

      const guam_discount_price =
        ((parseFloat(product.guam_original_price) +
          parseFloat(size.guam_quantity_price)) *
          parseFloat(size.guam_discount_percent)) /
        100;

      const saipan_discount_price =
        ((parseFloat(product.saipan_original_price) +
          parseFloat(size.saipan_quantity_price)) *
          parseFloat(size.saipan_discount_percent)) /
        100;

      if (tokenLib.getCountry() === 'guam') {
        switch (order.count_type) {
          case 'quantity':
            total_price =
              product.type === 'sale'
                ? (parseFloat(product.guam_original_price) +
                    parseFloat(size.guam_quantity_price) -
                    guam_discount_price) *
                  parseFloat(order.count)
                : (parseFloat(product.guam_original_price) +
                    parseFloat(size.guam_quantity_price)) *
                  parseFloat(order.count);
            break;

          default:
            break;
        }
      } else {
        switch (order.count_type) {
          case 'quantity':
            total_price =
              product.type === 'sale'
                ? (parseFloat(product.saipan_original_price) +
                    parseFloat(size.saipan_quantity_price) -
                    saipan_discount_price) *
                  parseFloat(order.count)
                : (parseFloat(product.saipan_original_price) +
                    parseFloat(size.saipan_quantity_price)) *
                  parseFloat(order.count);
            break;

          default:
            break;
        }
      }

      array = [
        ...array,
        {
          ...order,
          total_price,
        },
      ];
    }

    setOrderList(array);
  }, [purchase]);

  return (
    <>
      <div className={cn('contents-products')}>
        <div className={cn('contents-products-headers')}>
          <div
            className={cn('contents-products-headers-section')}
            style={{ width: '50%' }}
          >
            Order list
          </div>

          <div
            className={cn('contents-products-headers-section')}
            style={{ width: '15%' }}
          >
            Count
          </div>

          <div
            className={cn('contents-products-headers-section')}
            style={{ width: '15%' }}
          >
            Payment Amount
          </div>

          <div
            className={cn('contents-products-headers-section')}
            style={{ width: '20%' }}
          >
            Delete Selected Product
          </div>
        </div>

        <div className={cn('contents-products-list')}>
          {purchase &&
            orderList.map((data: any, index: number) => {
              if (data.delete) return <></>;

              let product: any;

              for (const p of purchase.product_list) {
                if (p.id === data.product_id) product = p;
              }

              if (!product) return <></>;

              return (
                <div key={index} className={cn('contents-products-list-item')}>
                  <div
                    className={cn('contents-products-list-item-section')}
                    style={{ width: '50%' }}
                  >
                    <div
                      className={cn('contents-products-list-item-section-info')}
                    >
                      <img
                        className={cn(
                          'contents-products-list-item-section-info-img'
                        )}
                        src={
                          product.image_list ? product.image_list[0].image : ''
                        }
                        alt='product_img'
                        draggable={false}
                      />

                      <div
                        className={cn(
                          'contents-products-list-item-section-info-wrap'
                        )}
                      >
                        <span
                          className={cn(
                            'contents-products-list-item-section-info-wrap-brand'
                          )}
                        >
                          {product.brand.name}
                        </span>

                        <span
                          className={cn(
                            'contents-products-list-item-section-info-wrap-product'
                          )}
                        >
                          {product.name}
                        </span>

                        <span
                          className={cn(
                            'contents-products-list-item-section-info-wrap-price'
                          )}
                        >
                          <span
                            className={cn(
                              'contents-products-list-item-section-info-wrap-price-bold'
                            )}
                          >
                            Price:
                          </span>{' '}
                          ${handlePrice(data).toLocaleString()}
                          {handleDiscount(data, product)}
                        </span>

                        <span
                          className={cn(
                            'contents-products-list-item-section-info-wrap-option'
                          )}
                        >
                          <span
                            className={cn(
                              'contents-products-list-item-section-info-wrap-option-bold'
                            )}
                          >
                            Option:
                          </span>{' '}
                          {`${
                            data.measurement === 'ml'
                              ? `${data.size} ml`
                              : `${data.size} fl oz`
                          }${
                            product.product_type === 'default'
                              ? ''
                              : `/${
                                  product.color_list.filter(
                                    (c: any) => String(c.id) === data.color_id
                                  )[0].name
                                } (${
                                  product.color_list.filter(
                                    (c: any) => String(c.id) === data.color_id
                                  )[0].code
                                })`
                          }`}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={cn('contents-products-list-item-section')}
                    style={{ width: '15%' }}
                  >
                    <div
                      className={cn(
                        'contents-products-list-item-section-quantity'
                      )}
                    >
                      <div
                        className={cn(
                          'contents-products-list-item-section-quantity-icon_wrap'
                        )}
                        onClick={() => handleCount(data, product, 'minus')}
                      >
                        <Remove
                          className={cn(
                            'contents-products-list-item-section-quantity-icon_wrap-icon'
                          )}
                        />
                      </div>

                      <span
                        className={cn(
                          'contents-products-list-item-section-quantity-number'
                        )}
                      >
                        {data.count}
                      </span>

                      <div
                        className={cn(
                          'contents-products-list-item-section-quantity-icon_wrap'
                        )}
                        onClick={() => handleCount(data, product, 'plus')}
                      >
                        <Add
                          className={cn(
                            'contents-products-list-item-section-quantity-icon_wrap-icon'
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className={cn('contents-products-list-item-section')}
                    style={{ width: '15%' }}
                  >
                    <div
                      className={cn(
                        'contents-products-list-item-section-amount'
                      )}
                    >
                      <span
                        className={cn(
                          'contents-products-list-item-section-amount-number'
                        )}
                      >
                        ${parseFloat(data.total_price).toLocaleString()}
                      </span>

                      {/* <button
                      className={cn(
                        'contents-products-list-item-section-amount-btn'
                      )}
                    >
                      Buy Now
                    </button> */}
                    </div>
                  </div>

                  <div
                    className={cn('contents-products-list-item-section')}
                    style={{ width: '20%' }}
                  >
                    <button
                      className={cn('contents-products-list-item-section-btn')}
                      onClick={() => handleD(data.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className={cn('contents-delivery_option')}>
        <div className={cn('contents-delivery_option-header')}>
          Delivery Option
        </div>

        <div className={cn('contents-delivery_option-options')}>
          <div className={cn('contents-delivery_option-options-wrap')}>
            <span className={cn('contents-delivery_option-options-wrap-text')}>
              In-store Pickup
            </span>

            <span
              className={cn('contents-delivery_option-options-wrap-subtext')}
            >
              {`[For Saipan and Other Regional Customers ONLY]`}
              <br />
              {`Our shipping-cost policy is as follows:`}
              <br />
              {`☆$1,000 or more--> Free Shipment`}
              <br />
              {`☆$500.00 over 50/50`}
              <br />
              {`Customer is responsible for 50%`}
              <br />
              {`☆UNDER $500 60/40`}
              <br />
              {`Customer is responsible for 60%`}
              <br />
              {`We kindly ask for your understanding that the cost of delivery can be separately charged after the payment for the items.`}
            </span>
          </div>
        </div>
      </div>

      <div className={cn('contents-payment_amount')}>
        <div className={cn('contents-payment_amount-headers')}>
          <div
            className={cn('contents-payment_amount-headers-section')}
            style={{ width: '100%' }}
          >
            Payment Amount
          </div>
        </div>

        <div className={cn('contents-payment_amount-result')}>
          <span className={cn('contents-payment_amount-result-text')}>
            {user.customer_type !== 'general' ? (
              <span className={cn('contents-payment_amount-result-text-sale')}>
                ${handleTotal().toLocaleString()}
              </span>
            ) : (
              <></>
            )}
            ${handleTotal(user.customer_type).toLocaleString()}
          </span>
        </div>
      </div>
    </>
  );
}

function Confirm({
  purchase,
  orderList,
  inputs,
  onChange,
}: {
  purchase: any;
  orderList: any[];
  inputs: PurchaseUser;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <>
      <div className={cn('contents-user_info')}>
        <div className={cn('contents-user_info-wrap')}>
          <span className={cn('contents-user_info-wrap-text')}>
            Full name
            <br />
            (First and Last name)
          </span>

          <input
            className={cn('contents-user_info-wrap-input')}
            placeholder='Full Name'
            name='name'
            value={inputs.name}
            onChange={onChange}
          />
        </div>

        <div className={cn('contents-user_info-wrap')}>
          <span className={cn('contents-user_info-wrap-text')}>
            Delivery Location
          </span>

          <div className={cn('contents-user_info-wrap-input_wrap')}>
            <input
              className={cn('contents-user_info-wrap-input_wrap-input')}
              placeholder='name of business name or Street name'
              name='address'
              value={inputs.address}
              onChange={onChange}
            />

            <input
              className={cn('contents-user_info-wrap-input_wrap-input')}
              placeholder='suite, unit, floor, etc.'
              name='detail_address'
              value={inputs.detail_address}
              onChange={onChange}
            />
          </div>
        </div>

        <div className={cn('contents-user_info-wrap')}>
          <span className={cn('contents-user_info-wrap-text')}>
            Phone number
          </span>

          <input
            className={cn('contents-user_info-wrap-input')}
            placeholder='Phone number'
            name='mobile'
            value={inputs.mobile}
            onChange={onChange}
          />
        </div>
      </div>

      <div className={cn('contents-order')}>
        <div className={cn('contents-order-headers')}>
          <div
            className={cn('contents-order-headers-section')}
            style={{ width: '60%' }}
          >
            Order list
          </div>

          <div
            className={cn('contents-order-headers-section')}
            style={{ width: '30%' }}
          >
            Option
          </div>

          <div
            className={cn('contents-order-headers-section')}
            style={{ width: '10%' }}
          >
            Count
          </div>

          {/* <div
            className={cn('contents-order-headers-section')}
            style={{ width: '10%' }}
          >
            Payment
          </div> */}
        </div>

        <div className={cn('contents-order-list')}>
          {purchase &&
            orderList.map((data: any, index: number) => {
              if (data.delete) return <></>;

              const product = purchase.product_list.find(
                (p: any) => p.id === data.product_id
              );

              return (
                <div key={index} className={cn('contents-order-list-item')}>
                  <div
                    className={cn('contents-order-list-item-section')}
                    style={{ width: '60%' }}
                  >
                    <div
                      className={cn('contents-order-list-item-section-info')}
                    >
                      <img
                        className={cn(
                          'contents-order-list-item-section-info-img'
                        )}
                        src={product.image_list[0].image}
                        alt='product_img'
                        draggable={false}
                      />

                      <div
                        className={cn(
                          'contents-order-list-item-section-info-wrap'
                        )}
                      >
                        <span
                          className={cn(
                            'contents-order-list-item-section-info-wrap-product'
                          )}
                        >
                          {product.name}
                        </span>

                        <span
                          className={cn(
                            'contents-order-list-item-section-info-wrap-brand'
                          )}
                        >
                          {product.brand.name}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div
                    className={cn('contents-order-list-item-section')}
                    style={{ width: '30%' }}
                  >
                    {data.measurement === 'ml'
                      ? `${data.size} ml`
                      : `${data.size} fl oz`}
                    {product.product_type === 'default'
                      ? ''
                      : `/${
                          product.color_list.filter(
                            (c: any) => String(c.id) === data.color_id
                          )[0].name
                        } (${
                          product.color_list.filter(
                            (c: any) => String(c.id) === data.color_id
                          )[0].code
                        })`}
                  </div>

                  <div
                    className={cn('contents-order-list-item-section')}
                    style={{ width: '10%' }}
                  >
                    {String(data.count) +
                      (data.count_type === 'box' ? ' Box' : '')}
                  </div>

                  {/* <div
                    className={cn('contents-order-list-item-section')}
                    style={{ width: '10%' }}
                  >
                    ${parseFloat(data.total_price).toLocaleString()}
                  </div> */}
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

function Completed() {
  return (
    <>
      <div className={cn('contents-completed')}>
        <TaskAlt className={cn('contents-completed-icon')} />

        <span className={cn('contents-completed-text')}>Thank you!</span>
      </div>
    </>
  );
}

type PurchaseProps = {
  user: any;
  purchase: any;
  orderList: any[];
  setOrderList: (state: any[]) => void;
  inputs: PurchaseUser;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleDelete: (list: any) => void;
  handleBuy: (setType: (state: string) => void) => void;
};

export default function Purchase({
  user,
  purchase,
  orderList,
  setOrderList,
  inputs,
  onChange,
  handleDelete,
  handleBuy,
}: PurchaseProps) {
  const history = useHistory();

  const [type, setType] = useState<'shopping' | 'confirm' | 'completed'>(
    'shopping'
  );

  const handleTotal = (type: string = 'none') => {
    let price = 0;

    orderList.forEach((order) => (price += parseFloat(order.total_price)));

    switch (type) {
      case 'special': // 10%
        return price - Math.floor((price * 10) / 100);

      case 'wholesale': // 40%
        return price - Math.floor((price * 40) / 100);

      case 'salon': // 50%
        return price - Math.floor((price * 50) / 100);

      default:
        return price;
    }
  };

  const handlePrice = (order: any, type: string = 'none') => {
    let total_price = 0;

    let product: any;

    for (const p of purchase.product_list) {
      if (p.id === order.product_id) product = p;
    }

    const size = product.size_list.find(
      (s: any) => s.size === order.size && s.measurement === order.measurement
    );

    const guam_discount_price =
      ((parseFloat(product.guam_original_price) +
        parseFloat(size.guam_quantity_price)) *
        parseFloat(size.guam_discount_percent)) /
      100;

    const saipan_discount_price =
      ((parseFloat(product.saipan_original_price) +
        parseFloat(size.saipan_quantity_price)) *
        parseFloat(size.saipan_discount_percent)) /
      100;

    if (tokenLib.getCountry() === 'guam') {
      switch (order.count_type) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.guam_original_price) +
                  parseFloat(size.guam_quantity_price) -
                  guam_discount_price) *
                1
              : (parseFloat(product.guam_original_price) +
                  parseFloat(size.guam_quantity_price)) *
                1;
          break;

        default:
          break;
      }
    } else {
      switch (order.count_type) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.saipan_original_price) +
                  parseFloat(size.saipan_quantity_price) -
                  saipan_discount_price) *
                1
              : (parseFloat(product.saipan_original_price) +
                  parseFloat(size.saipan_quantity_price)) *
                1;
          break;

        default:
          break;
      }
    }

    switch (type) {
      case 'special': // 10%
        return (total_price - Math.floor((total_price * 10) / 100)).toString();

      case 'wholesale': // 40%
        return (total_price - Math.floor((total_price * 40) / 100)).toString();

      case 'salon': // 50%
        return (total_price - Math.floor((total_price * 50) / 100)).toString();

      default:
        return total_price;
    }
  };

  const handleOrderList = () => {
    let array: any[] = [];

    for (const data of orderList) {
      if (data.delete) continue;

      const product = purchase.product_list.find(
        (p: any) => p.id === data.product_id
      );

      array = [
        ...array,
        {
          name: product.name, // 상품 이름
          quantity: String(data.count), // 수량
          unit_amount: {
            currency_code: 'USD', // 통화 코드
            value:
              tokenLib.getCountry() === 'guam'
                ? handlePrice(data, user.customer_type)
                : handlePrice(data, user.customer_type), // 단위 가격
          },
        },
      ];
    }

    return array;
  };

  return (
    <Box>
      <span className={cn('title')}>
        {type === 'shopping'
          ? 'Shopping bag'
          : type === 'confirm'
          ? 'Confirmation'
          : 'Purchase completed'}
      </span>

      <div className={cn('process')}>
        <div className={cn('process-line')} />

        <div className={cn('process-wrap')}>
          <span
            className={cn(
              `process-wrap-${type === 'shopping' ? 'select_number' : 'number'}`
            )}
          >
            01
          </span>

          <div
            className={cn(
              `process-wrap-${type === 'shopping' ? 'select_circle' : 'circle'}`
            )}
          />

          <span
            className={cn(
              `process-wrap-${type === 'shopping' ? 'select_title' : 'title'}`
            )}
          >
            Shopping bag
          </span>
        </div>

        <div className={cn('process-wrap')}>
          <span
            className={cn(
              `process-wrap-${type === 'confirm' ? 'select_number' : 'number'}`
            )}
          >
            02
          </span>

          <div
            className={cn(
              `process-wrap-${type === 'confirm' ? 'select_circle' : 'circle'}`
            )}
          />

          <span
            className={cn(
              `process-wrap-${type === 'confirm' ? 'select_title' : 'title'}`
            )}
          >
            Confirmation
          </span>
        </div>

        <div className={cn('process-wrap')}>
          <span
            className={cn(
              `process-wrap-${
                type === 'completed' ? 'select_number' : 'number'
              }`
            )}
          >
            03
          </span>

          <div
            className={cn(
              `process-wrap-${
                type === 'completed' ? 'select_circle' : 'circle'
              }`
            )}
          />

          <span
            className={cn(
              `process-wrap-${type === 'completed' ? 'select_title' : 'title'}`
            )}
          >
            Purchase completed
          </span>
        </div>
      </div>

      {(purchase && orderList.length) || type === 'completed' ? (
        <>
          <div className={cn('contents')}>
            {type === 'shopping' ? (
              <Shopping
                user={user}
                purchase={purchase}
                orderList={orderList}
                setOrderList={setOrderList}
                handleDelete={handleDelete}
              />
            ) : type === 'confirm' ? (
              <Confirm
                purchase={purchase}
                orderList={orderList}
                inputs={inputs}
                onChange={onChange}
              />
            ) : (
              <Completed />
            )}
          </div>

          {type !== 'confirm' ? (
            <button
              className={cn('btn')}
              onClick={() => {
                switch (type) {
                  case 'shopping':
                    setType('confirm');

                    break;

                  case 'completed':
                    history.push('/');

                    break;

                  default:
                    break;
                }
              }}
            >
              {type === 'shopping' ? 'Next' : 'Continue Shopping'}
            </button>
          ) : (
            <PayPalButtons
              className={cn('paypal_btn')}
              createOrder={(_data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        currency_code: 'USD', // 통화 코드 (예: USD)
                        value: handleTotal(user.customer_type).toString(), // 결제할 금액
                        breakdown: {
                          item_total: {
                            currency_code: 'USD',
                            value: handleTotal(user.customer_type).toString(), // 모든 개별 품목의 총 가격
                          },
                        },
                      },
                      items: handleOrderList(),
                    },
                  ],
                });
              }}
              onApprove={(_data, actions) => {
                if (!actions.order) return new Promise(() => {});

                return actions.order.capture().then(function (details) {
                  // 결제가 승인되면 실행할 코드 작성
                  handleBuy(setType as (state: string) => void);
                });
              }}
            />
          )}
        </>
      ) : (
        <span className={cn('empty')} onClick={() => history.push('/')}>
          Your shopping bag is empty. Go to see products.
        </span>
      )}
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 150px 200px;

  .empty {
    width: fit-content;
    height: fit-content;
    display: flex;
    margin: 50px auto;
    font-size: 28px;
    font-weight: 500;
    user-select: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .title {
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 100px;
  }

  .process {
    width: 80%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    position: relative;
    margin: 0 auto;
    margin-bottom: 100px;

    &-line {
      width: 100%;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #c2c2c2;
      z-index: 1;
    }

    &-wrap {
      width: fit-content;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 100px;
      z-index: 2;

      &:last-child {
        margin-right: 0;
      }

      &-number {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
        color: #c2c2c2;
      }

      &-circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #c2c2c2;
        margin-bottom: 8px;
      }

      &-title {
        font-size: 14px;
        font-weight: 500;
        color: #c2c2c2;
      }

      &-select_number {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
        color: #000;
      }

      &-select_circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #000;
        margin-bottom: 8px;
      }

      &-select_title {
        font-size: 14px;
        font-weight: 500;
        color: #000;
      }
    }
  }

  .contents {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;

    &-products {
      width: 100%;
      height: fit-content;

      &-headers {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        border-top: 3px solid #000;
        border-bottom: 1px solid #c2c2c2;
        background-color: #f4f4f4;

        &-section {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding: 20px;
          font-size: 16px;
          font-weight: 600;
        }
      }

      &-list {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        &-item {
          width: 100%;
          height: 210px;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #c2c2c2;

          &-section {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              border-right: 1px solid #c2c2c2;
            }

            &-info {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;

              &-img {
                width: 154px;
                height: 154px;
                object-fit: contain;
                object-position: center;
                padding: 10px;
                margin-right: 20px;
                border: 1px solid #828282;
              }

              &-wrap {
                width: fit-content;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;

                &-brand {
                  font-size: 16px;
                  font-weight: 300;
                  color: #828282;
                  margin-bottom: 15px;
                }

                &-product {
                  font-size: 16px;
                  font-weight: 500;
                  color: #333333;
                  margin-bottom: 15px;
                }

                &-price {
                  font-size: 16px;
                  font-weight: 300;
                  color: #828282;
                  margin-bottom: 15px;

                  &-bold {
                    font-size: 16px;
                    font-weight: 500;
                    color: #828282;
                  }
                }

                &-option {
                  font-size: 16px;
                  font-weight: 300;
                  color: #828282;

                  &-bold {
                    font-size: 16px;
                    font-weight: 500;
                    color: #828282;
                  }
                }
              }
            }

            &-quantity {
              width: fit-content;
              height: fit-content;
              display: flex;
              flex-direction: row;

              &-icon_wrap {
                width: 45px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f4f4f4;
                border: 1px solid #d9d9d9;
                cursor: pointer;

                &-icon {
                  width: 20px;
                  height: 20px;
                  fill: #8f8f8f;
                }
              }

              &-number {
                width: 70px;
                height: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #333333;
                font-weight: 500;
                font-size: 16px;
                border-top: 1px solid #d9d9d9;
                border-bottom: 1px solid #d9d9d9;
              }
            }

            &-amount {
              width: 100%;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;

              &-number {
                width: fit-content;
                height: fit-content;
                font-size: 20px;
                font-weight: 500;
                color: #333;
              }

              &-btn {
                width: fit-content;
                height: fit-content;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 25px;
                border: 2px solid #8f8f8f;
                border-radius: 5px;
                background-color: #000;
                color: #fff;
                cursor: pointer;
                outline: none;
                font-weight: 600;
                margin-top: 15px;
              }
            }

            &-btn {
              width: fit-content;
              height: fit-content;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 15px 35px;
              border: 2px solid #8f8f8f;
              border-radius: 5px;
              background-color: #000;
              color: #fff;
              cursor: pointer;
              outline: none;
              font-weight: 600;
            }
          }
        }
      }
    }

    &-delivery_option {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      margin-top: 60px;

      &-header {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 3px solid #000;
        border-bottom: 1px solid #c2c2c2;
        background-color: #f4f4f4;
        padding: 20px 0;
        font-size: 16px;
        font-weight: 600;
      }

      &-options {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        border-bottom: 1px solid #c2c2c2;
        padding: 60px 0;

        &-wrap {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          &-text {
            font-size: 28px;
            font-weight: 500;
            user-select: none;
            margin-bottom: 50px;
          }

          &-subtext {
            line-height: 1.8;
            text-align: center;
            font-size: 16px;
          }
        }
      }
    }

    &-payment_amount {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      margin-top: 60px;

      &-headers {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        border-top: 3px solid #000;
        border-bottom: 1px solid #c2c2c2;
        background-color: #f4f4f4;

        &-section {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding: 20px;
          font-size: 16px;
          font-weight: 600;
        }
      }

      &-result {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 60px 0;
        border-bottom: 1px solid #c2c2c2;

        &-text {
          width: calc((100% - 92px) / 3);
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          font-size: 30px;
          font-weight: 600;

          &-sale {
            font-size: 18px;
            font-weight: 500;
            font-style: italic;
            text-decoration: line-through;
            color: #ff6b6b;
          }
        }

        &-icon_wrap {
          width: 45px;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 1px solid #dbdbdb;
          background-color: #f4f4f4;

          &-icon {
            width: 65%;
            height: auto;
            fill: #8f8f8f;
          }
        }
      }
    }

    &-user_info {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 50px;
      margin-bottom: 50px;
      background-color: #f8f6ec;

      &-wrap {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }

        &-text {
          width: 35%;
          height: fit-content;
          font-size: 14px;
          font-weight: 600;
        }

        &-input {
          width: 65%;
          height: 45px;
          padding: 15px 40px;
          outline: none;
          border: 1px solid #acacac;

          &::placeholder {
            color: #acacac;
          }
        }

        &-input_wrap {
          width: 65%;
          height: fit-content;
          display: flex;
          flex-direction: column;

          &-input {
            width: 100%;
            height: 45px;
            padding: 15px 40px;
            outline: none;
            border: 1px solid #acacac;

            &::placeholder {
              color: #acacac;
            }

            &:last-child {
              margin-top: 10px;
            }
          }
        }
      }
    }

    &-order {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-headers {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: row;
        border-top: 3px solid #000;
        border-bottom: 1px solid #c2c2c2;
        background-color: #f4f4f4;

        &-section {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          padding: 20px;
          font-size: 16px;
          font-weight: 600;
        }
      }

      &-list {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        &-item {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #c2c2c2;

          &-section {
            width: 100%;
            height: fit-content;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 25px;
            margin: auto 0;
            font-size: 14px;
            font-weight: 300;

            &-info {
              width: 100%;
              height: fit-content;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;

              &-img {
                width: 64px;
                height: 64px;
                object-fit: contain;
                object-position: center;
                padding: 10px;
                margin-right: 20px;
                border: 1px solid #828282;
              }

              &-wrap {
                width: fit-content;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                flex-direction: column;

                &-product {
                  font-size: 16px;
                  font-weight: 500;
                  color: #333333;
                  margin-bottom: 10px;
                }

                &-brand {
                  font-size: 16px;
                  font-weight: 300;
                  color: #828282;
                }
              }
            }
          }
        }
      }
    }

    &-completed {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &-icon {
        width: 60px;
        height: auto;
        fill: #f15a53;
        margin-bottom: 20px;
      }

      &-text {
        font-size: 50px;
        user-select: none;
      }
    }
  }

  .btn {
    width: 300px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border: 2px solid #8f8f8f;
    border-radius: 5px;
    background-color: #000;
    color: #fff;
    cursor: pointer;
    outline: none;
    font-weight: 600;
  }

  .paypal_btn {
    width: 500px;
    height: fit-content;
    margin: 0 auto;
  }
`;
