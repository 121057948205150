import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { Payload } from '../reducer/user.reducer';

import * as ACTION from '../action/user.action';
import API from '../api/user.api';
import tokenLib from '../../library/token.lib';

function* signin(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.signin, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.SIGN_IN_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.SIGN_IN_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.SIGN_IN_FAILURE,
      payload: { data: error },
    });
  }
}

function* createUser(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.postUser, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.USER_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.USER_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.USER_FAILURE,
      payload: { data: error },
    });
  }
}

function* updateUser(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(
      tokenLib.getRefreshToken() ? API.putMyUser : API.putUser,
      action.payload.data
    );

    action.payload.success(data);

    yield put({
      type: ACTION.UPDATE_USERINFO_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.UPDATE_USERINFO_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.UPDATE_USERINFO_FAILURE,
      payload: { data: error },
    });
  }
}

function* getUser(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.getMyUser);

    action.payload.success(data);

    yield put({
      type: ACTION.USER_DETAIL_SUCCESS,
      payload: { data: data },
    });
  } catch (error: any) {
    action.payload.failure(error.response.data);

    yield put({
      type: ACTION.USER_DETAIL_FAILURE,
      payload: { data: error.response.data },
    });
  }
}

function* watchSignIn() {
  yield takeLatest(ACTION.SIGN_IN_REQUEST, signin);
}

function* watchCreateUser() {
  yield takeLatest(ACTION.USER_REQUEST, createUser);
}

function* watchGetUser() {
  yield takeLatest(ACTION.USER_DETAIL_REQUEST, getUser);
}

function* watchUpdateUserInfo() {
  yield takeLatest(ACTION.UPDATE_USERINFO_REQUEST, updateUser);
}

function* userSaga() {
  yield all([
    fork(watchSignIn),
    fork(watchCreateUser),
    fork(watchGetUser),
    fork(watchUpdateUserInfo),
  ]);
}

export default userSaga;
