import { handleActions } from 'redux-actions';

import * as Action from '../action/product.action';

export type State = {
  favorite_product_list: any[];
  purchase: any;
  purchase_list: any[];
  product: any;
  product_list: any[];
  product_list_1: any[];
  product_list_2: any[];
  product_list_3: any[];
  product_list_4: any[];
  totalPage_1: number;
  totalPage_2: number;
  totalPage_3: number;
  totalPage_4: number;
  category_menu_list: any[];
  category_sub_menu_list: any;
  brand_list: any[];
  banner_list: any[];
  search_list: any;
  notice: any;
};

export type Payload = {
  data: any;
  success: (response: any) => void;
  failure: (error: any) => void;
};

const initialState: State = {
  favorite_product_list: [],
  purchase: null,
  purchase_list: [],
  product: null,
  product_list: [],
  product_list_1: [],
  product_list_2: [],
  product_list_3: [],
  product_list_4: [],
  totalPage_1: 1,
  totalPage_2: 1,
  totalPage_3: 1,
  totalPage_4: 1,
  category_menu_list: [],
  category_sub_menu_list: null,
  brand_list: [],
  banner_list: [],
  search_list: null,
  notice: null,
};

const product = handleActions<State, Payload>(
  {
    [Action.SEARCH_SUCCESS]: (state, action) => ({
      ...state,
      search_list: action.payload.data,
    }),
    [Action.SEARCH_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.CATEGORY_MENU_SUCCESS]: (state, action) => ({
      ...state,
      category_menu_list: action.payload.data,
    }),
    [Action.CATEGORY_MENU_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.CATEGORY_SUB_MENU_SUCCESS]: (state, action) => ({
      ...state,
      category_sub_menu_list: action.payload.data,
    }),
    [Action.CATEGORY_SUB_MENU_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.FAVORITE_PRODUCT_SUCCESS]: (state, action) => ({
      ...state,
    }),
    [Action.FAVORITE_PRODUCT_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.MY_FAVORITE_PRODUCTS_SUCCESS]: (state, action) => ({
      ...state,
      favorite_product_list: action.payload.data,
    }),
    [Action.MY_FAVORITE_PRODUCTS_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.PRODUCT_LIST_SUCCESS]: (state, action) => ({
      ...state,
      product_list: action.payload.data,
    }),
    [Action.PRODUCT_LIST_1_SUCCESS]: (state, action) => ({
      ...state,
      product_list_1: action.payload.data.data,
      totalPage_1: action.payload.data.totalPage,
    }),
    [Action.PRODUCT_LIST_2_SUCCESS]: (state, action) => ({
      ...state,
      product_list_2: action.payload.data.data,
      totalPage_2: action.payload.data.totalPage,
    }),
    [Action.PRODUCT_LIST_3_SUCCESS]: (state, action) => ({
      ...state,
      product_list_3: action.payload.data.data,
      totalPage_3: action.payload.data.totalPage,
    }),
    [Action.PRODUCT_LIST_4_SUCCESS]: (state, action) => ({
      ...state,
      product_list_4: action.payload.data.data,
      totalPage_4: action.payload.data.totalPage,
    }),
    [Action.PRODUCT_LIST_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.PRODUCT_DETAIL_SUCCESS]: (state, action) => ({
      ...state,
      product: action.payload.data,
    }),
    [Action.PRODUCT_DETAIL_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.BANNER_LIST_SUCCESS]: (state, action) => ({
      ...state,
      banner_list: action.payload.data,
    }),
    [Action.BANNER_LIST_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.NOTICE_SUCCESS]: (state, action) => ({
      ...state,
      notice: action.payload.data,
    }),
    [Action.NOTICE_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.PURCHASE_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.PURCHASE_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.PURCHASE_DETAIL_SUCCESS]: (state, action) => ({
      ...state,
      purchase: action.payload.data,
    }),
    [Action.PURCHASE_DETAIL_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.BRAND_LIST_SUCCESS]: (state, action) => ({
      ...state,
      brand_list: action.payload.data,
    }),
    [Action.BRAND_LIST_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.PURCHASE_LIST_SUCCESS]: (state, action) => ({
      ...state,
      purchase_list: action.payload.data,
    }),
    [Action.PURCHASE_LIST_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.PURCHASE_UPDATE_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.PURCHASE_UPDATE_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.INQUIRY_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.INQUIRY_FAILURE]: (state, _action) => ({
      ...state,
    }),
  },
  initialState
);

export default product;
