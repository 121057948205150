import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Component from '../../components/mobile_favorite';
import { RootState } from '../../redux/reducer';
import {
  handleFavoriteProductRequest,
  handleMyFavoriteProductsRequest,
} from '../../redux/action/product.action';
import { useToasts } from 'react-toast-notifications';

export default function MobileFavorite() {
  const { addToast } = useToasts();

  const dispath = useDispatch();
  const { favorite_product_list } = useSelector(
    ({ product }: RootState) => product
  );

  const handleFavorite = (id: string) => {
    function success(response: any) {
      addToast('Deleted to my favorites.', { appearance: 'success' });

      function success() {}
      function failure() {}

      dispath(handleMyFavoriteProductsRequest(success, failure));
    }
    function failure() {}

    dispath(handleFavoriteProductRequest({ product_id: id }, success, failure));
  };

  useEffect(() => {
    function success() {}
    function failure() {}

    dispath(handleMyFavoriteProductsRequest(success, failure));
  }, []);

  return (
    <Component
      favorite_product_list={favorite_product_list}
      handleFavorite={handleFavorite}
    />
  );
}
