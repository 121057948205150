import { combineReducers } from 'redux';

import product from './product.reducer';
import user from './user.reducer';

const rootReducer = combineReducers({
  product,
  user,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
