import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Component from '../../components/product';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import {
  handleFavoriteProductRequest,
  handleProductDetailRequest,
  handlePurchaseRequest,
} from '../../redux/action/product.action';
import { useToasts } from 'react-toast-notifications';
import tokenLib from '../../library/token.lib';

export const quantityOptions = new Array(100).fill(0).map((_data, index) => {
  return {
    value: index + 1,
    label: `${index + 1}`,
  };
});

export const boxOptions = new Array(100).fill(0).map((_data, index) => {
  return {
    value: index,
    label: `${index} box`,
  };
});

export default function Product() {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const { addToast } = useToasts();

  const dispath = useDispatch();
  const { product } = useSelector(({ product }: RootState) => product);

  const [selectQuantity, setSelectQuantity] = useState(quantityOptions[0]);
  const [selectBox, setSelectBox] = useState(boxOptions[0]);
  const [isSelect, setIsSelect] = useState<'none' | 'quantity' | 'box'>(
    'quantity'
  );
  const [selectSize, setSelectSize] = useState<any>(null);
  const [selectColor, setSelectColor] = useState<any>(null);

  const handleQuantity = (props: any) => {
    setSelectQuantity(props);
    setIsSelect('quantity');
  };

  const handleFavorite = () => {
    if (!tokenLib.getRefreshToken()) {
      addToast('Please use it after logging in', { appearance: 'warning' });

      return;
    }

    function success(response: any) {
      if (response.message === 'favorite_deleted') {
        addToast('Deleted to my favorites.', { appearance: 'success' });

        return;
      }

      addToast('Added to my favorites.', { appearance: 'success' });
    }
    function failure() {}

    dispath(handleFavoriteProductRequest({ product_id: id }, success, failure));
  };

  const handlePurchase = (type: 'buy' | 'bag') => {
    if (!tokenLib.getRefreshToken()) {
      addToast('Please use it after logging in', { appearance: 'warning' });

      return;
    }

    if (isSelect === 'none') {
      addToast('Please select quantity or box', { appearance: 'warning' });

      return;
    }

    function success() {
      addToast('Added to shopping bag', { appearance: 'success' });

      setSelectQuantity(quantityOptions[0]);
      setSelectBox(boxOptions[0]);
      setIsSelect('quantity');
      setSelectSize(product.size_list[0]);

      if (type === 'buy') history.push('/purchase');
    }
    function failure() {}

    const request = {
      product_id: id,
      count_type: isSelect,
      count: isSelect === 'box' ? selectBox.value : selectQuantity.value,
      size: selectSize,
      color_id: selectColor,
    };

    dispath(handlePurchaseRequest(request, success, failure));
  };

  useEffect(() => {
    function success(response: any) {
      setSelectSize(response.size_list.length ? response.size_list[0] : null);
      setSelectColor(
        response.color_list.length ? response.color_list[0].id : null
      );
    }
    function failure() {}

    dispath(handleProductDetailRequest(id, success, failure));
  }, [id]);

  return product ? (
    <Component
      product={product}
      selectSize={selectSize}
      setSelectSize={setSelectSize}
      selectQuantity={selectQuantity}
      handleQuantity={handleQuantity}
      selectBox={selectBox}
      handleFavorite={handleFavorite}
      isSelect={isSelect}
      handlePurchase={handlePurchase}
      selectColor={selectColor}
      setSelectColor={setSelectColor}
    />
  ) : (
    <></>
  );
}
