import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Component from '../../components/mobile_main';
import { RootState } from '../../redux/reducer';
import { handleProductListRequest } from '../../redux/action/product.action';

export default function MobileMain() {
  const dispath = useDispatch();
  const { product_list_1, product_list_2, product_list_3, product_list_4 } =
    useSelector(({ product }: RootState) => product);

  const [index1, setIndex1] = useState<number>(1);
  const [index2, setIndex2] = useState<number>(1);
  const [index3, setIndex3] = useState<number>(1);
  const [index4, setIndex4] = useState<number>(1);

  useEffect(() => {
    function success() {}
    function failure() {}

    dispath(
      handleProductListRequest(
        {
          id: process.env.REACT_APP_ENVIRONMENT === 'development' ? 2 : 16,
          page: index1,
          limit: 3,
          type: 1,
        },
        success,
        failure
      )
    );
  }, [index1]);

  useEffect(() => {
    function success() {}
    function failure() {}

    dispath(
      handleProductListRequest(
        {
          id: process.env.REACT_APP_ENVIRONMENT === 'development' ? 2 : 17,
          page: index2,
          limit: 3,
          type: 2,
        },
        success,
        failure
      )
    );
  }, [index2]);

  useEffect(() => {
    function success() {}
    function failure() {}

    dispath(
      handleProductListRequest(
        {
          id: process.env.REACT_APP_ENVIRONMENT === 'development' ? 2 : 18,
          page: index3,
          limit: 2,
          type: 3,
        },
        success,
        failure
      )
    );
  }, [index3]);

  useEffect(() => {
    function success() {}
    function failure() {}

    dispath(
      handleProductListRequest(
        {
          id: process.env.REACT_APP_ENVIRONMENT === 'development' ? 2 : 19,
          page: index4,
          limit: 2,
          type: 4,
        },
        success,
        failure
      )
    );
  }, [index4]);

  return (
    <Component
      product_list_1={product_list_1}
      product_list_2={product_list_2}
      product_list_3={product_list_3}
      product_list_4={product_list_4}
      index1={index1}
      setIndex1={setIndex1}
      index2={index2}
      setIndex2={setIndex2}
      index3={index3}
      setIndex3={setIndex3}
      index4={index4}
      setIndex4={setIndex4}
    />
  );
}
