import { useParams } from 'react-router-dom';
import Component from '../../components/shop_by_brand';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleBrandListRequest } from '../../redux/action/product.action';
import { RootState } from '../../redux/reducer';

export default function ShopByBrand() {
  const { id } = useParams<{ id: string }>();

  const dispath = useDispatch();
  const { brand_list } = useSelector(({ product }: RootState) => product);

  useEffect(() => {
    function success() {}
    function failure() {}

    dispath(handleBrandListRequest(success, failure));
  }, [id]);

  return <Component brand_list={brand_list} />;
}
