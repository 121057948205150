import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Component from '../../components/profile';
import { RootState } from '../../redux/reducer';
import {
  handleUpdateUserInfoRequest,
  handleUserDetailRequest,
} from '../../redux/action/user.action';
import useInputs from '../../hooks/useInputs';
import upload from '../../library/upload.lib';
import crypto from 'crypto';
import { useToasts } from 'react-toast-notifications';

const initial_form = {
  name: '',
  email: '',
  password: '',
  confirm_password: '',
  mobile: '',
  address: '',
  detail_address: '',
};

export type FormType = typeof initial_form;

export default function Profile() {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { user } = useSelector(({ user }: RootState) => user);

  const [image, setImage] = useState<File[]>([]);
  const [form, onChange, reset] = useInputs(initial_form);

  const handleUpdate = async () => {
    function success() {
      addToast('User information modified.', { appearance: 'success' });

      function success(response: any) {
        reset({
          name: response.name,
          email: response.email,
          mobile: response.mobile,
          address: response.address_list[0].address,
          detail_address: response.address_list[0].detail_address,
          password: '',
          confirm_password: '',
        });
        setImage([]);
      }
      function failure() {}

      dispatch(handleUserDetailRequest(success, failure));
    }

    function failure() {}

    if (form.password !== form.confirm_password) {
      addToast('Passwords do not match.', { appearance: 'warning' });

      return;
    }

    let request: any = {
      name: form.name,
      email: form.email,
      mobile: form.mobile,
      address: form.address,
      detail_address: form.detail_address,
    };

    let img = '';

    if (image.length) {
      console.log(image);

      await upload(
        image[0],
        `${process.env.REACT_APP_ENVIRONMENT}/user/${user.id}`
      )
        .then((s3_response) => {
          img = `https://system4u.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/user/${user.id}/${s3_response.timestamp}.jpeg`;
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (img !== '') {
      request = {
        ...request,
        image: img,
      };
    }

    if (form.password !== '') {
      request = {
        ...request,
        password: crypto
          .createHash('sha512')
          .update(form.password)
          .digest('hex'),
      };
    }

    dispatch(handleUpdateUserInfoRequest(request, success, failure));
  };

  useEffect(() => {
    function success(response: any) {
      reset({
        name: response.name,
        email: response.email,
        mobile: response.mobile,
        address: response.address_list[0].address,
        detail_address: response.address_list[0].detail_address,
        password: '',
        confirm_password: '',
      });
      setImage([]);
    }
    function failure() {}

    dispatch(handleUserDetailRequest(success, failure));
  }, []);

  return (
    <Component
      form={form}
      onChange={onChange}
      image={image}
      setImage={setImage}
      handleUpdate={handleUpdate}
    />
  );
}
