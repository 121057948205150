import { ChangeEvent } from 'react';
import styled from 'styled-components';
import cn from 'classnames';

import MyPageNav from '../common/MyPageNav';
import { FormType } from '../../containers/profile';
import ImageUpload from '../common/ImageUpload';

type ProfileProps = {
  form: FormType;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  image: File[];
  setImage: (state: File[]) => void;
  handleUpdate: () => void;
};

export default function Profile({
  form,
  onChange,
  image,
  setImage,
  handleUpdate,
}: ProfileProps) {
  return (
    <MyPageNav>
      <Box>
        <div className={cn('wrap')}>
          <span className={cn('wrap-text')}>
            Full name
            <br />
            (First and Last name)
          </span>

          <input
            className={cn('wrap-input')}
            placeholder='Full Name'
            name='name'
            value={form.name}
            onChange={onChange}
          />
        </div>

        <div className={cn('wrap')}>
          <span className={cn('wrap-text')}>Email</span>

          <input
            className={cn('wrap-input')}
            placeholder='Email'
            name='email'
            value={form.email}
            onChange={onChange}
          />
        </div>

        <div className={cn('wrap')}>
          <span className={cn('wrap-text')}>New Password</span>

          <input
            className={cn('wrap-input')}
            placeholder='New Password'
            type='password'
            name='password'
            value={form.password}
            onChange={onChange}
          />
        </div>

        <div className={cn('wrap')}>
          <span className={cn('wrap-text')}>Confirm New Password</span>

          <input
            className={cn('wrap-input')}
            placeholder='Confirm New Password'
            type='password'
            name='confirm_password'
            value={form.confirm_password}
            onChange={onChange}
          />
        </div>

        <div className={cn('wrap')}>
          <span className={cn('wrap-text')}>Phone number</span>

          <input
            className={cn('wrap-input')}
            placeholder='Phone number'
            name='mobile'
            value={form.mobile}
            onChange={onChange}
          />
        </div>

        <div className={cn('wrap')}>
          <span className={cn('wrap-text')}>Delivery Location</span>

          <div className={cn('wrap-input_wrap')}>
            <input
              className={cn('wrap-input_wrap-input')}
              placeholder='name of business name or Street name'
              name='address'
              value={form.address}
              onChange={onChange}
            />

            <input
              className={cn('wrap-input_wrap-input')}
              placeholder='suite, unit, floor, etc.'
              name='detail_address'
              value={form.detail_address}
              onChange={onChange}
            />
          </div>
        </div>

        <div className={cn('wrap')}>
          <span className={cn('wrap-text')}>License attach file</span>

          <ImageUpload
            images={image}
            setImages={setImage}
            style={{ width: '70%' }}
          />
        </div>

        <button className={cn('btn')} onClick={handleUpdate}>
          Change
        </button>
      </Box>
    </MyPageNav>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 50px;
  background-color: #f0f6ec;

  .wrap {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 25px;

    &:last-child {
      margin-bottom: 0;
    }

    &-text {
      width: 30%;
      height: fit-content;
      font-size: 14px;
      font-weight: 600;
    }

    &-input {
      width: 70%;
      height: 45px;
      padding: 15px 30px;
      outline: none;
      border: 1px solid #acacac;

      &::placeholder {
        color: #acacac;
      }
    }

    &-input_wrap {
      width: 70%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-input {
        width: 100%;
        height: 45px;
        padding: 15px 30px;
        outline: none;
        border: 1px solid #acacac;

        &::placeholder {
          color: #acacac;
        }

        &:last-child {
          margin-top: 10px;
        }
      }
    }

    &-file {
      width: 35%;
      height: fit-content;
      padding: 10px 15px;
      border: 1px solid #acacac;
      background-color: #fff;
      font-size: 14px;
      color: #acacac;
    }

    &-btn {
      width: fit-content;
      height: fit-content;
      padding: 7px 11px;
      border: 2px solid #8f8f8f;
      border-radius: 5px;
      font-size: 16px;
      font-weight: 600;
      background-color: #000;
      color: #fff;
      cursor: pointer;
      margin-left: 25px;
      margin-right: auto;
    }
  }

  .btn {
    width: fit-content;
    height: fit-content;
    padding: 14px 120px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background: #f68b50;
    border: 2px solid #e3580b;
    border-radius: 5px;
    margin: 100px 0 0 auto;
    cursor: pointer;
  }
`;
