import { ChangeEvent } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import CheckBox from '../common/CheckBox';
import ImageUpload from '../common/ImageUpload';
import { SignInFormType, SignUpFormType } from '../../containers/sign';

type MobileSignProps = {
  isLoading: boolean;
  isSign: boolean;
  handleIsSign: (State: boolean) => void;
  isPurchased: string;
  setIsPurchased: (state: string) => void;
  licensedStudent: boolean;
  setLicensedStudent: (state: boolean) => void;
  salonBusiness: boolean;
  setSalonBusiness: (state: boolean) => void;
  other: boolean;
  setOther: (state: boolean) => void;
  isCountry: boolean;
  setIsCountry: (state: boolean) => void;
  image: File[];
  setImage: (state: File[]) => void;
  signInForm: SignInFormType;
  onChangeSignIn: (event: ChangeEvent<HTMLInputElement>) => void;
  signUpForm: SignUpFormType;
  onChangeSignUp: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSignIn: () => void;
  handleSignUp: () => void;
};

export default function MobileSign({
  isLoading,
  isSign,
  handleIsSign,
  isPurchased,
  setIsPurchased,
  licensedStudent,
  setLicensedStudent,
  salonBusiness,
  setSalonBusiness,
  isCountry,
  setIsCountry,
  image,
  setImage,
  signInForm,
  onChangeSignIn,
  signUpForm,
  onChangeSignUp,
  handleSignIn,
  handleSignUp,
  other,
  setOther,
}: MobileSignProps) {
  const handleCustomerType = (type: 'wholesale' | 'salon' | 'general') => {
    switch (type) {
      case 'general':
        setLicensedStudent(false);
        setSalonBusiness(false);
        setOther(true);

        break;

      case 'salon':
        setLicensedStudent(false);
        setSalonBusiness(true);
        setOther(false);

        break;

      case 'wholesale':
        setLicensedStudent(true);
        setSalonBusiness(false);
        setOther(false);

        break;

      default:
        break;
    }
  };

  return (
    <Box>
      {!isSign ? (
        <div className={cn('signin')}>
          <div className={cn('signin-left_panel')}>
            <span className={cn('signin-left_panel-title')}>Login</span>

            <input
              className={cn('signin-left_panel-input')}
              placeholder='Email'
              name='email'
              value={signInForm.email}
              onChange={onChangeSignIn}
            />

            <input
              className={cn('signin-left_panel-input')}
              type='password'
              placeholder='Password'
              name='password'
              value={signInForm.password}
              onChange={onChangeSignIn}
            />

            <button
              className={cn('signin-left_panel-btn')}
              onClick={handleSignIn}
            >
              Login
            </button>
          </div>

          <div className={cn('signin-line')} />

          <div className={cn('signin-right_panel')}>
            <span className={cn('signin-right_panel-title')}>
              Quick Register
            </span>

            <button
              className={cn('signin-right_panel-btn')}
              onClick={() => handleIsSign(true)}
            >
              Quick Register
            </button>
          </div>
        </div>
      ) : (
        <div className={cn('signup')}>
          <span className={cn('signup-title')}>NEW CUSTOMERS</span>

          <div className={cn('signup-intro')}>
            Welcome!
            <br />
            Before we get started we're going to need some information from you.
          </div>

          <span className={cn('signup-subtitle')}>
            Have you ever purchased with SYSTEM4U before?
          </span>

          <button
            className={cn('signup-btn1', {
              'signup-btn1-select': isPurchased === 'YES',
            })}
            style={{ marginBottom: '10px' }}
            onClick={() => setIsPurchased('YES')}
          >
            Yes
          </button>

          <button
            className={cn('signup-btn1', {
              'signup-btn1-select': isPurchased === 'NO',
            })}
            style={{ marginBottom: '50px' }}
            onClick={() => setIsPurchased('NO')}
          >
            No
          </button>

          <CheckBox
            isCheckBox={licensedStudent}
            onClick={() => handleCustomerType('wholesale')}
            style={{ marginBottom: '10px' }}
            contentsStyle={{ fontWeight: 500 }}
          >
            Licensed Professional OR Cosmetology Students
          </CheckBox>

          <CheckBox
            isCheckBox={salonBusiness}
            onClick={() => handleCustomerType('salon')}
            style={{ marginBottom: '10px' }}
            contentsStyle={{ fontWeight: 500 }}
          >
            Salon / Business Owner
          </CheckBox>

          <CheckBox
            isCheckBox={other}
            onClick={() => handleCustomerType('general')}
            style={{ marginBottom: '20px' }}
            contentsStyle={{ fontWeight: 500 }}
          >
            Other
          </CheckBox>

          <span className={cn('signup-file_title')}>
            Please provide an image of your{' '}
            <span className={cn('signup-file_title2')}>
              Business License, Professional License, or Student ID
            </span>
          </span>

          <ImageUpload
            images={image}
            setImages={setImage}
            style={{ width: '100%', marginBottom: '50px' }}
          />

          <span className={cn('signup-subtitle')}>Profile Infomation</span>

          <div className={cn('signup-wrap')}>
            <input
              className={cn('signup-input')}
              placeholder='First Name'
              name='first_name'
              value={signUpForm.first_name}
              onChange={onChangeSignUp}
            />

            <input
              className={cn('signup-input')}
              placeholder='Last Name'
              name='last_name'
              value={signUpForm.last_name}
              onChange={onChangeSignUp}
            />

            <input
              className={cn('signup-input')}
              style={{ marginBottom: 0 }}
              placeholder='Email'
              name='email'
              value={signUpForm.email}
              onChange={onChangeSignUp}
            />
          </div>

          <input
            type='password'
            className={cn('signup-input')}
            placeholder='Password'
            name='password'
            value={signUpForm.password}
            onChange={onChangeSignUp}
          />

          <input
            type='password'
            className={cn('signup-input')}
            placeholder='Confirm Password'
            name='confirm_password'
            value={signUpForm.confirm_password}
            onChange={onChangeSignUp}
          />

          <input
            className={cn('signup-input')}
            placeholder='Phone'
            name='mobile'
            value={signUpForm.mobile}
            onChange={onChangeSignUp}
          />

          {salonBusiness ? (
            <>
              <input
                className={cn('signup-input')}
                placeholder='name of business name or Street name'
                style={{ width: '630px' }}
                name='business_name'
                value={signUpForm.business_name}
                onChange={onChangeSignUp}
              />

              <input
                className={cn('signup-input')}
                placeholder='suite, unit, floor, etc.'
                style={{ width: '630px' }}
                name='etc'
                value={signUpForm.etc}
                onChange={onChangeSignUp}
              />
            </>
          ) : (
            <></>
          )}

          <span className={cn('signup-subtitle')} style={{ marginTop: '40px' }}>
            Please select your area.
          </span>

          <button
            className={cn('signup-btn1', { 'signup-btn1-select': isCountry })}
            style={{ marginBottom: '10px' }}
            onClick={() => setIsCountry(true)}
          >
            Guam
          </button>

          <button
            className={cn('signup-btn1', {
              'signup-btn1-select': !isCountry,
            })}
            onClick={() => setIsCountry(false)}
          >
            Saipan & Other Regions
          </button>

          <div className={cn('signup-btn_wrap')}>
            <button
              className={cn('signup-btn_wrap-btn1')}
              onClick={() => handleIsSign(false)}
            >
              Go to Sign in
            </button>

            <button
              className={cn('signup-btn_wrap-btn2')}
              onClick={() => {
                if (!isLoading) handleSignUp();
              }}
            >
              Sign up
            </button>
          </div>
        </div>
      )}
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  padding: 100px 0;

  .signin {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin: auto;

    &-left_panel {
      width: fit-content;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      &-title {
        width: 310px;
        height: fit-content;
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 25px;
        user-select: none;
      }

      &-input {
        width: 310px;
        height: 50px;
        border: 1px solid #acacac;
        padding: 15px;
        margin-bottom: 15px;
        outline: none;

        &::placeholder {
          color: #acacac;
        }
      }

      &-btn {
        width: 310px;
        height: 50px;
        margin-top: 15px;
        border: 2px solid #8f8f8f;
        border-radius: 5px;
        background-color: #000;
        color: #fff;
        font-weight: 500;
        outline: none;
        cursor: pointer;
      }
    }

    &-line {
      width: 100%;
      height: 2px;
      margin: 50px 0;
      background-color: #c2c2c2;
    }

    &-right_panel {
      width: fit-content;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;

      &-title {
        width: 310px;
        height: fit-content;
        font-size: 36px;
        font-weight: 500;
        margin-bottom: 25px;
        user-select: none;
      }

      &-btn {
        width: 310px;
        height: 50px;
        border: 2px solid #8f8f8f;
        border-radius: 5px;
        background-color: #000;
        color: #fff;
        font-weight: 500;
        outline: none;
        cursor: pointer;
      }
    }
  }

  .signup {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0 10px;

    &-title {
      font-size: 36px;
      font-weight: 500;
      margin-bottom: 40px;
    }

    &-intro {
      font-size: 20px;
      font-weight: 300;
      margin-bottom: 35px;
    }

    &-subtitle {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    &-btn1 {
      width: 100%;
      height: 50px;
      border: 2px solid #292929;
      border-radius: 5px;
      background-color: #fff;
      color: #292929;
      font-weight: 500;
      outline: none;
      cursor: pointer;

      &-select {
        border-color: #e3580b;
        background-color: #f68b50;
        color: #fff;
      }
    }

    &-file_title {
      font-size: 14px;
      margin-bottom: 10px;
    }

    &-file_title2 {
      font-size: 14px;
      font-weight: 600;
      color: #f15a53;
      text-decoration: underline;
    }

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }

    &-input {
      width: 100%;
      height: 50px;
      border: 1px solid #acacac;
      padding: 15px;
      margin-bottom: 10px;
      outline: none;

      &::placeholder {
        color: #acacac;
      }
    }

    &-btn_wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      margin-top: 100px;

      &-btn1 {
        width: 100%;
        height: 50px;
        margin-bottom: 10px;
        border: 2px solid #292929;
        border-radius: 5px;
        background-color: #fff;
        color: #292929;
        font-weight: 500;
        outline: none;
        cursor: pointer;
      }

      &-btn2 {
        width: 100%;
        height: 50px;
        border: 2px solid #8f8f8f;
        border-radius: 5px;
        background-color: #000;
        color: #fff;
        font-weight: 500;
        outline: none;
        cursor: pointer;
      }
    }
  }
`;
