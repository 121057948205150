import { useDispatch, useSelector } from 'react-redux';
import Component from '../../components/mobile_order_history';
import { useEffect } from 'react';
import { RootState } from '../../redux/reducer';
import { handlePurchaseListRequest } from '../../redux/action/product.action';

export default function MobileOrderHistory() {
  const dispatch = useDispatch();
  const { purchase_list } = useSelector(({ product }: RootState) => product);

  useEffect(() => {
    function success() {}
    function failure() {}

    dispatch(handlePurchaseListRequest(success, failure));
  }, []);

  return <Component purchase_list={purchase_list} />;
}
