import PageTemplate from '../containers/common/PageTemplate';

import Container from '../containers/mobile_profile';

export default function MobileProfile() {
  return (
    <>
      <PageTemplate type={true}>
        <Container />
      </PageTemplate>
    </>
  );
}
