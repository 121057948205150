import { handleActions } from 'redux-actions';

import * as Action from '../action/user.action';

export type State = {
  user: any;
};

export type Payload = {
  data: any;
  success: (response: any) => void;
  failure: (error: any) => void;
};

const initialState: State = {
  user: null,
};

const user = handleActions<State, Payload>(
  {
    [Action.SIGN_IN_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.SIGN_IN_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.USER_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.USER_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.UPDATE_USERINFO_SUCCESS]: (state, _action) => ({
      ...state,
    }),
    [Action.UPDATE_USERINFO_FAILURE]: (state, _action) => ({
      ...state,
    }),

    [Action.USER_DETAIL_SUCCESS]: (state, action) => ({
      ...state,
      user: action.payload.data,
    }),
    [Action.USER_DETAIL_FAILURE]: (state, action) => ({
      ...state,
    }),
  },
  initialState
);

export default user;
