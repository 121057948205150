import { ReactChild, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Component from '../../components/common/PageTemplate';
import MobileComponent from '../../components/common/MobilePageTemplate';

import { RootState } from '../../redux/reducer';
import {
  handleCategoryMenuRequest,
  handleInquiryRequest,
  handleNoticeRequest,
  handleSearchRequest,
} from '../../redux/action/product.action';
import { useToasts } from 'react-toast-notifications';

type PageTemplateProps = {
  type?: boolean;
  children: ReactChild | ReactChild[];
};

export default function PageTemplate({
  children,
  type = false,
}: PageTemplateProps) {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { search_list, category_menu_list, notice } = useSelector(
    ({ product }: RootState) => product
  );

  const [search, setSearch] = useState('');

  const [isModal, setIsModal] = useState(false);
  const [cellphone, setCellphone] = useState('');
  const [email, setEmail] = useState('');
  const [inquiry, setInquiry] = useState('');

  const handleSearch = () => {
    function success() {}

    function failure() {}

    dispatch(handleSearchRequest(search, success, failure));
  };

  const handleInquiry = () => {
    function success() {
      setIsModal(false);

      addToast('You have submitted your inquiry.', { appearance: 'success' });
    }

    function failure() {}

    dispatch(
      handleInquiryRequest({ cellphone, email, inquiry }, success, failure)
    );
  };

  useEffect(() => {
    function success() {}
    function failure() {}

    dispatch(handleCategoryMenuRequest(success, failure));
    dispatch(handleNoticeRequest(success, failure));
  }, []);

  useEffect(() => {
    if (!isModal) {
      setCellphone('');
      setEmail('');
      setInquiry('');
    }
  }, [isModal]);

  return (
    <>
      {type ? (
        <MobileComponent
          category_menu_list={category_menu_list}
          notice={notice && notice.status ? notice.text : ''}
          search_list={search_list}
          search={search}
          setSearch={setSearch}
          handleSearch={handleSearch}
          cellphone={cellphone}
          setCellphone={setCellphone}
          email={email}
          setEmail={setEmail}
          inquiry={inquiry}
          setInquiry={setInquiry}
          isModal={isModal}
          setIsModal={setIsModal}
          handleInquiry={handleInquiry}
        >
          {children}
        </MobileComponent>
      ) : (
        <Component
          search_list={search_list}
          category_menu_list={category_menu_list}
          notice={notice && notice.status ? notice.text : ''}
          search={search}
          setSearch={setSearch}
          cellphone={cellphone}
          setCellphone={setCellphone}
          email={email}
          setEmail={setEmail}
          inquiry={inquiry}
          setInquiry={setInquiry}
          handleSearch={handleSearch}
          isModal={isModal}
          setIsModal={setIsModal}
          handleInquiry={handleInquiry}
        >
          {children}
        </Component>
      )}
    </>
  );
}
