import { useEffect, useState } from 'react';
import Component from '../../components/purchase';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';
import {
  handlePurchaseDetailRequest,
  handlePurchaseUpdateRequest,
} from '../../redux/action/product.action';
import useInputs from '../../hooks/useInputs';
import {
  handleUpdateUserInfoRequest,
  handleUserDetailRequest,
} from '../../redux/action/user.action';
import { useToasts } from 'react-toast-notifications';
import tokenLib from '../../library/token.lib';

const initial = {
  name: '',
  mobile: '',
  address: '',
  detail_address: '',
};

export type PurchaseUser = typeof initial;

export default function Purchase() {
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const { purchase } = useSelector(({ product }: RootState) => product);
  const { user } = useSelector(({ user }: RootState) => user);

  const [inputs, onChange, reset] = useInputs(initial);
  const [orderList, setOrderList] = useState<any[]>([]);

  const handleDelete = (list: any) => {
    function successPurchase() {
      function success(response: any) {
        setOrderList(response.detail_list);
      }
      function failure() {}

      dispatch(handlePurchaseDetailRequest(success, failure));
    }

    function failurePurchase() {}

    dispatch(
      handlePurchaseUpdateRequest(
        {
          id: purchase.id,
          detail_list: list,
        },
        successPurchase,
        failurePurchase
      )
    );
  };

  const handlePrice = (order: any, type: string = 'none') => {
    let total_price = 0;

    let product: any;

    for (const p of purchase.product_list) {
      if (p.id === order.product_id) product = p;
    }

    const size = product.size_list.find(
      (s: any) => s.size === order.size && s.measurement === order.measurement
    );

    const guam_discount_price =
      ((parseFloat(product.guam_original_price) +
        parseFloat(size.guam_quantity_price)) *
        parseFloat(size.guam_discount_percent)) /
      100;

    const saipan_discount_price =
      ((parseFloat(product.saipan_original_price) +
        parseFloat(size.saipan_quantity_price)) *
        parseFloat(size.saipan_discount_percent)) /
      100;

    if (tokenLib.getCountry() === 'guam') {
      switch (order.count_type) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.guam_original_price) +
                  parseFloat(size.guam_quantity_price) -
                  guam_discount_price) *
                1
              : (parseFloat(product.guam_original_price) +
                  parseFloat(size.guam_quantity_price)) *
                1;
          break;

        default:
          break;
      }
    } else {
      switch (order.count_type) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.saipan_original_price) +
                  parseFloat(size.saipan_quantity_price) -
                  saipan_discount_price) *
                1
              : (parseFloat(product.saipan_original_price) +
                  parseFloat(size.saipan_quantity_price)) *
                1;
          break;

        default:
          break;
      }
    }

    switch (type) {
      case 'special': // 10%
        return total_price - Math.floor((total_price * 10) / 100);

      case 'wholesale': // 40%
        return total_price - Math.floor((total_price * 40) / 100);

      case 'salon': // 50%
        return total_price - Math.floor((total_price * 50) / 100);

      default:
        return total_price;
    }
  };

  const handleBuy = (setType: (state: string) => void) => {
    function successUser() {
      function successPurchase() {
        setType('completed');

        addToast('Complete purchase request.', { appearance: 'success' });

        function success() {}
        function failure() {}

        dispatch(handlePurchaseDetailRequest(success, failure));
      }

      function failurePurchase() {}

      dispatch(
        handlePurchaseUpdateRequest(
          {
            id: purchase.id,
            delivery: 'pickup',
            quam_delivery_price: null,
            saipan_delivery_price: null,
            detail_list: orderList.map((data) => {
              return {
                ...data,
                total_price: handlePrice(data, user.customer_type),
              };
            }),
            status: 'pending',
          },
          successPurchase,
          failurePurchase
        )
      );
    }

    function failureUser() {}

    dispatch(
      handleUpdateUserInfoRequest({ ...inputs }, successUser, failureUser)
    );
  };

  useEffect(() => {
    function success(response: any) {
      reset({
        name: response.user.name,
        mobile: response.user.mobile,
        address: response.user.address_list[0].address,
        detail_address: response.user.address_list[0].detail_address,
      });

      setOrderList(response.detail_list);
    }
    function failure() {}

    dispatch(handlePurchaseDetailRequest(success, failure));

    function successUser() {}
    function failureUser() {}

    dispatch(handleUserDetailRequest(successUser, failureUser));
  }, []);

  return (
    <>
      {user ? (
        <Component
          user={user}
          purchase={purchase}
          orderList={orderList}
          setOrderList={setOrderList}
          inputs={inputs}
          onChange={onChange}
          handleDelete={handleDelete}
          handleBuy={handleBuy}
        />
      ) : (
        <></>
      )}
    </>
  );
}
