import { ReactChild, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import LOGO_IMG from '../../static/images/logo.png';
import ICON_1 from '../../static/images/ic_mypage.svg';
import ICON_2 from '../../static/images/ic_shopping.svg';
import ICON_3 from '../../static/images/ic_login.svg';
import tokenLib from '../../library/token.lib';
import mobile from '../../library/mobile.lib';
import { Chat, Close, Search } from '@mui/icons-material';

type PageTemplateProps = {
  search: string;
  setSearch: (state: string) => void;
  cellphone: string;
  setCellphone: (state: string) => void;
  email: string;
  setEmail: (state: string) => void;
  inquiry: string;
  setInquiry: (state: string) => void;
  search_list: any;
  category_menu_list: any[];
  notice?: string;
  handleSearch: () => void;
  isModal: boolean;
  setIsModal: (state: boolean) => void;
  handleInquiry: () => void;
  children: ReactChild | ReactChild[];
};

export default function PageTemplate({
  search_list,
  search,
  setSearch,
  cellphone,
  email,
  inquiry,
  setCellphone,
  setEmail,
  setInquiry,
  category_menu_list,
  notice,
  handleSearch,
  isModal,
  setIsModal,
  handleInquiry,
  children,
}: PageTemplateProps) {
  const history = useHistory();

  const [isSearch, setIsSearch] = useState(false);

  const [isSub1, setIsSub1] = useState(false);
  const [isSub2, setIsSub2] = useState(false);
  const [isSub3, setIsSub3] = useState(false);
  const [isSub4, setIsSub4] = useState(false);
  const [isSub5, setIsSub5] = useState(false);

  const [isMy, setIsMy] = useState(false);

  const handleSubDisplay = (name: string) => {
    switch (name) {
      case 'hair_care':
        return isSub1 ? 'flex' : 'none';

      case 'hair_color':
        return isSub2 ? 'flex' : 'none';

      case 'tools_appliances':
        return isSub3 ? 'flex' : 'none';

      case 'salon_essentials':
        return isSub4 ? 'flex' : 'none';

      case 'skin_body':
        return isSub5 ? 'flex' : 'none';

      default:
        return 'none';
    }
  };

  const handleSub = useCallback(
    (prop: boolean, type: '1' | '2' | '3' | '4' | '5') => {
      switch (type) {
        case '1':
          setIsSub1(prop);

          break;

        case '2':
          setIsSub2(prop);

          break;

        case '3':
          setIsSub3(prop);

          break;

        case '4':
          setIsSub4(prop);

          break;

        case '5':
          setIsSub5(prop);

          break;

        default:
          break;
      }
    },
    [isSub1, isSub2, isSub3]
  );

  const handlePush = (path: string) => {
    history.push(path);
  };

  useEffect(() => {
    const isMobile = mobile();

    if (isMobile) {
      history.push('/mobile');
    }
  }, []);

  return (
    <>
      <Box>
        <div className={cn('header')}>
          <div className={cn('header-1')}>
            <img
              className={cn('header-1-logo')}
              src={LOGO_IMG}
              alt='logo'
              draggable={false}
              onClick={() => handlePush('/')}
            />

            <div className={cn('header-1-search')}>
              <input
                className={cn('header-1-search-input')}
                placeholder='Search'
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setIsSearch(false);
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    if (search === '') return;

                    handleSearch();
                    setIsSearch(true);
                  }
                }}
              />

              <Search
                className={cn('header-1-search-icon')}
                onClick={() => {
                  if (search === '') return;

                  handleSearch();
                  setIsSearch(true);
                }}
              />

              {isSearch && search_list ? (
                <div className={cn('header-1-search-wrap')}>
                  <div className={cn('header-1-search-wrap-left')}>
                    <span className={cn('header-1-search-wrap-left-title')}>
                      Product
                    </span>

                    {search_list.product_list.map(
                      (data: any, index: number) => {
                        return (
                          <div
                            key={index}
                            className={cn('header-1-search-wrap-left-item')}
                            onClick={() => {
                              history.push(`/product/${data.id}`);
                              setIsSearch(false);
                              setSearch('');
                            }}
                          >
                            {data.image_list.length ? (
                              <img
                                src={data.image_list[0].image}
                                alt='product_img'
                                draggable={false}
                                className={cn(
                                  'header-1-search-wrap-left-item-img'
                                )}
                              />
                            ) : (
                              <></>
                            )}

                            <span
                              className={cn(
                                'header-1-search-wrap-left-item-name'
                              )}
                            >
                              {data.name}
                            </span>
                          </div>
                        );
                      }
                    )}
                  </div>

                  <div className={cn('header-1-search-wrap-right')}>
                    <span className={cn('header-1-search-wrap-right-title')}>
                      Brand
                    </span>

                    {search_list.brand_list.map((data: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className={cn('header-1-search-wrap-right-item')}
                          onClick={() => {
                            history.push(`/products/${data.category_id}`);
                            setIsSearch(false);
                            setSearch('');
                          }}
                        >
                          {data.image ? (
                            <img
                              src={data.image}
                              alt='product_img'
                              draggable={false}
                              className={cn(
                                'header-1-search-wrap-right-item-img'
                              )}
                            />
                          ) : (
                            <></>
                          )}

                          <span
                            className={cn(
                              'header-1-search-wrap-right-item-name'
                            )}
                          >
                            {data.name}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className={cn('header-1-wrap')}>
              {tokenLib.getRefreshToken() ? (
                <>
                  <div
                    className={cn('header-1-wrap-item')}
                    onMouseEnter={() => {
                      setIsMy(true);
                    }}
                    onMouseLeave={() => {
                      setIsMy(false);
                    }}
                  >
                    <img
                      className={cn('header-1-wrap-item-img')}
                      src={ICON_1}
                      alt='icon'
                      draggable={false}
                      onClick={() => handlePush('/order-history')}
                    />

                    <span
                      className={cn('header-1-wrap-item-text')}
                      onClick={() => handlePush('/order-history')}
                    >
                      My Page
                    </span>

                    <div
                      className={cn('header-1-wrap-item-sub_menu_list')}
                      style={{ display: isMy ? 'flex' : 'none' }}
                    >
                      <span
                        className={cn('header-1-wrap-item-sub_menu_list-item')}
                        onClick={() => {
                          handlePush('/order-history');
                        }}
                      >
                        Order History
                      </span>

                      <span
                        className={cn('header-1-wrap-item-sub_menu_list-item')}
                        onClick={() => {
                          handlePush('/favorite');
                        }}
                      >
                        My Favorite
                      </span>

                      <span
                        className={cn('header-1-wrap-item-sub_menu_list-item')}
                        onClick={() => {
                          handlePush('/profile');
                        }}
                      >
                        Change My Profile
                      </span>
                    </div>
                  </div>

                  <div
                    className={cn('header-1-wrap-item')}
                    onClick={() => handlePush('/purchase')}
                  >
                    <img
                      className={cn('header-1-wrap-item-img')}
                      src={ICON_2}
                      alt='icon'
                      draggable={false}
                    />

                    <span className={cn('header-1-wrap-item-text')}>
                      Shopping Bag
                    </span>
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                className={cn('header-1-wrap-item')}
                onClick={() => {
                  tokenLib.removeToken('ALL');

                  handlePush('/sign');
                }}
              >
                <img
                  className={cn('header-1-wrap-item-img')}
                  src={ICON_3}
                  alt='icon'
                  draggable={false}
                />

                <span className={cn('header-1-wrap-item-text')}>
                  {tokenLib.getRefreshToken() ? 'Logout' : 'Login / Sign Up'}
                </span>
              </div>
            </div>
          </div>

          <div className={cn('header-2')}>
            {category_menu_list &&
              category_menu_list.map((data, index) => {
                return (
                  <span
                    key={index}
                    className={cn('header-2-text')}
                    onClick={() =>
                      handlePush(
                        data.title === 'Shop By Brand'
                          ? '/shopbybrand'
                          : `/products/${data.id}`
                      )
                    }
                    onMouseEnter={() => {
                      switch (data.name) {
                        case 'hair_care':
                          handleSub(true, '1');

                          break;

                        case 'hair_color':
                          handleSub(true, '2');

                          break;

                        case 'tools_appliances':
                          handleSub(true, '3');

                          break;

                        case 'salon_essentials':
                          handleSub(true, '4');

                          break;

                        case 'skin_body':
                          handleSub(true, '5');

                          break;

                        default:
                          break;
                      }
                    }}
                    onMouseLeave={() => {
                      switch (data.name) {
                        case 'hair_care':
                          handleSub(false, '1');

                          break;

                        case 'hair_color':
                          handleSub(false, '2');

                          break;

                        case 'tools_appliances':
                          handleSub(false, '3');

                          break;

                        case 'salon_essentials':
                          handleSub(false, '4');

                          break;

                        case 'skin_body':
                          handleSub(false, '5');

                          break;

                        default:
                          break;
                      }
                    }}
                  >
                    {data.title}

                    {!isSearch && data.sub_category_list ? (
                      <div className={cn('header-2-sub_category_wrap')}>
                        <div
                          className={cn('header-2-sub_category')}
                          style={{ display: handleSubDisplay(data.name) }}
                        >
                          {data.sub_category_list.map(
                            (sc: any, index: number) => {
                              return (
                                <span
                                  key={index}
                                  className={cn('header-2-sub_category-text')}
                                  onClick={(e) => {
                                    e.stopPropagation();

                                    handlePush(`/products/${sc.id}`);
                                  }}
                                >
                                  {sc.title}
                                </span>
                              );
                            }
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </span>
                );
              })}
          </div>

          {notice ? <div className={cn('header-3')}>{notice}</div> : <></>}
        </div>

        <div className={cn('content')}>{children}</div>

        <div className={cn('footer')}>
          <div className={cn('footer-wrap')}>
            <img
              className={cn('footer-wrap-img')}
              src={LOGO_IMG}
              alt='logo'
              draggable={false}
            />

            <div className={cn('footer-wrap-text_wrap')}>
              <span className={cn('footer-wrap-text_wrap-text')}>
                Email: System4uguam@gmail.com
              </span>

              <span className={cn('footer-wrap-text_wrap-text')}>
                Phone: +1 671 646 4909/ +1 671 688 8780
              </span>

              <span className={cn('footer-wrap-text_wrap-text')}>
                Address: 719 S. Marine Corps Drive 104 Tamuning, Guam, 96913
              </span>

              <span className={cn('footer-wrap-text_wrap-text')}>
                Mailing Address: 425 Chalan San Antonio PMB 321 Tamuning, Guam
                96913
              </span>

              <div className={cn('footer-wrap-text_wrap-line')} />

              <span className={cn('footer-wrap-text_wrap-text')}>
                Copyrights © 2017 All Rights Reserved by SYSTEM4U
              </span>
            </div>
          </div>
        </div>

        <div className={cn('message')} onClick={() => setIsModal(true)}>
          <span className={cn('message-text')}>Ask Us!</span>

          <Chat className={cn('message-icon')} />
        </div>

        {isModal ? (
          <div className={cn('modal')}>
            <Close
              className={cn('modal-icon')}
              onClick={() => setIsModal(false)}
            />

            <div className={cn('modal-text')}>
              Hawaii adai,
              <br />
              Please ask us anything!
              <br />
              -or you can also call us, 671-646-4909
            </div>

            <div className={cn('modal-wrap')}>
              <span className={cn('modal-wrap-text')}>Cellphone number</span>

              <input
                className={cn('modal-wrap-input')}
                placeholder='cellphone...'
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
              />
            </div>

            <div className={cn('modal-wrap')}>
              <span className={cn('modal-wrap-text')}>Email (Optional)</span>

              <input
                className={cn('modal-wrap-input')}
                placeholder='email...'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className={cn('modal-wrap')}>
              <span className={cn('modal-wrap-text')}>
                Inquiry (Any language is fine!)
              </span>

              <textarea
                className={cn('modal-wrap-textarea')}
                placeholder='inquiry...'
                value={inquiry}
                onChange={(e) => setInquiry(e.target.value)}
              />
            </div>

            <button className={cn('modal-button')} onClick={handleInquiry}>
              SUBMIT
            </button>
          </div>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

const Box = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &-1 {
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      padding: 0 50px;
      border-bottom: 1px solid ${(prop) => prop.theme.gray};

      &-logo {
        width: auto;
        height: 35px;
        cursor: pointer;
        margin: 0 50px 0 0;
      }

      &-search {
        width: 400px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border: 1px solid #000;
        border-radius: 5px;
        margin: 0 auto 0 0;
        padding: 0 10px;

        &-input {
          width: calc(100% - 24px);
          height: 100%;
          outline: none;
          border: 0;

          &::placeholder {
            color: #c4c4c4;
          }
        }

        &-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }

        &-wrap {
          width: 100%;
          height: 230px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          background-color: #fff;
          border-radius: 5px;
          border: 1px solid #c4c4c4;
          z-index: 3;
          overflow: auto;

          &::-webkit-scrollbar {
            width: 0;
          }

          &-left {
            width: 50%;
            height: fit-content;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 0;
            border-right: 1px solid #868e96;
            padding: 5px;

            &-title {
              width: 100%;
              height: fit-content;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              color: #c4c4c4;
            }

            &-item {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
              padding: 10px;
              cursor: pointer;

              &-img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                object-position: center;
                margin-right: 10px;
              }

              &-name {
                font-size: 13px;
                font-weight: 500;
                user-select: none;
              }
            }
          }

          &-right {
            width: calc(50% - 1px);
            height: fit-content;
            min-height: 100%;
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 0;
            left: 50%;
            padding: 5px;

            &-title {
              width: 100%;
              height: fit-content;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              color: #c4c4c4;
            }

            &-item {
              width: 100%;
              height: 50px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
              padding: 10px;
              cursor: pointer;

              &-img {
                width: 30px;
                height: 30px;
                object-fit: cover;
                object-position: center;
                margin-right: 10px;
              }

              &-name {
                font-size: 13px;
                font-weight: 500;
                user-select: none;
              }
            }
          }
        }
      }

      &-wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;

        &-item {
          width: fit-content;
          height: fit-content;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          margin-right: 30px;
          padding: 15px 0;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &-img {
            width: auto;
            height: 20px;
            margin-right: 10px;
          }

          &-text {
            font-weight: 500;
            user-select: none;
          }

          &-sub_menu_list {
            width: 250px;
            height: fit-content;
            position: absolute;
            top: 100%;
            left: 0;
            display: none;
            flex-direction: column;
            background-color: #fff;
            box-shadow: 0 4px 7.05213px rgba(0, 0, 0, 0.35);
            z-index: 3;
            user-select: none;

            &-item {
              width: 100%;
              height: fit-content;
              padding: 10px 15px;
              font-size: 13px;

              &:hover {
                color: #fff;
                background-color: #f68b50;
              }
            }
          }
        }
      }
    }

    &-2 {
      width: 100%;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-direction: row;
      border-bottom: 1px solid ${(prop) => prop.theme.gray};

      &-text {
        position: relative;
        font-size: 20px;
        font-weight: 500;
        user-select: none;
        cursor: pointer;
      }

      &-sub_category_wrap {
        width: 250px;
        height: fit-content;
        position: absolute;
        top: 70%;
        left: -10px;
        padding-top: 20px;
        z-index: 3;
      }

      &-sub_category {
        width: 100%;
        height: fit-content;
        display: none;
        flex-direction: column;
        background-color: #fff;
        box-shadow: 0 4px 7.05213px rgba(0, 0, 0, 0.35);

        &-text {
          width: 100%;
          height: fit-content;
          padding: 10px 15px;
          font-size: 13px;

          &:hover {
            color: #fff;
            background-color: #f68b50;
          }
        }
      }
    }

    &-3 {
      width: 100%;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: ${(prop) => prop.theme.white};
      background-color: ${(prop) => prop.theme.primary};
    }
  }

  .content {
    width: 100%;
    height: fit-content;
  }

  .footer {
    width: 100%;
    height: 275px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    background-color: ${(prop) => prop.theme.primary};

    &-wrap {
      width: 1512px;
      height: 100%;
      display: flex;
      flex-direction: row;
      padding: 50px 0;

      &-img {
        width: 150px;
        height: auto;
        margin-right: 30px;
        margin-bottom: auto;
      }

      &-text_wrap {
        width: calc(100% - 180px);
        height: 100%;
        display: flex;
        flex-direction: column;

        &-text {
          font-size: 15px;
          font-weight: 300;
          margin: 3px 0;
          color: ${(prop) => prop.theme.white};
        }

        &-line {
          width: 100%;
          height: 1px;
          background-color: ${(prop) => prop.theme.white};
          margin: auto 0;
        }
      }
    }
  }

  .message {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 30px;
    bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 0 1px #dadcdf;
    cursor: pointer;

    &-text {
      width: 60px;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      bottom: calc(100% + 10px);
      transform: translateX(-50%);
      font-size: 13px;
      font-weight: 500;
      color: #88c763;
    }

    &-icon {
      fill: #88c763;
    }
  }

  .modal {
    width: 700px;
    height: 600px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    padding: 30px;
    box-shadow: 0 0 0 1px #dadcdf;

    &-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    &-text {
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.5;
      margin: 0 auto auto auto;
    }

    &-wrap {
      width: fit-content;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &-text {
        color: #88c763;
        font-weight: 500;
        margin-bottom: 5px;
      }

      &-input {
        width: 400px;
        height: 40px;
        padding: 15px;
        outline: none;
        border: 1px solid #acacac;

        &::placeholder {
          color: #acacac;
        }
      }

      &-textarea {
        width: 400px;
        height: 150px;
        padding: 15px;
        outline: none;
        border: 1px solid #acacac;
        resize: none;

        &::placeholder {
          color: #acacac;
        }
      }
    }

    &-button {
      width: fit-content;
      height: fit-content;
      padding: 14px 120px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      background: #f68b50;
      border: 2px solid #e3580b;
      border-radius: 5px;
      margin: auto auto 0 auto;
      cursor: pointer;
    }
  }
`;
