import styled from 'styled-components';
import cn from 'classnames';
import { Close } from '@mui/icons-material';

import { useHistory } from 'react-router-dom';
import tokenLib from '../../library/token.lib';

type MobileFavoriteProps = {
  favorite_product_list: any[];
  handleFavorite: (id: string) => void;
};

export default function MobileFavorite({
  favorite_product_list,
  handleFavorite,
}: MobileFavoriteProps) {
  const history = useHistory();

  return (
    <Box>
      <span className={cn('title')}>My Favorite</span>

      <div className={cn('list')}>
        {favorite_product_list.map((data, index) => {
          return (
            <div key={index} className={cn('item')}>
              <div
                className={cn('item-icon_wrap')}
                onClick={() => handleFavorite(data.id)}
              >
                <Close className={cn('item-icon_wrap-icon')} />
              </div>

              <img
                className={cn('item-img')}
                src={data.image_list[0].image}
                alt='item_img'
                onClick={() => history.push(`/mobile/product/${data.id}`)}
              />

              <span
                className={cn('item-brand')}
                onClick={() => history.push(`/mobile/product/${data.id}`)}
              >
                {data.brand.name}
              </span>

              <span
                className={cn('item-product')}
                onClick={() => history.push(`/mobile/product/${data.id}`)}
              >
                {data.name}
              </span>

              <span
                className={cn('item-sign')}
                onClick={() => {
                  if (!tokenLib.getRefreshToken()) history.push('/mobile/sign');
                }}
              >
                {!tokenLib.getRefreshToken()
                  ? 'Login or Register to buy'
                  : tokenLib.getCountry() === 'guam'
                  ? `$${Number(data.guam_original_price).toLocaleString()}`
                  : `$${Number(data.saipan_original_price).toLocaleString()}`}
              </span>
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .title {
    font-size: 30px;
    font-weight: 500;
    margin: 30px 10px;
  }

  .list {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 280px);
    row-gap: 20px;
    column-gap: 20px;
    padding: 30px;
  }

  .item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px;
    border: 1px solid #dbdbdb;

    &-icon_wrap {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 10px;
      left: calc(100% - 40px);
      border-radius: 5px;
      background-color: #344b42;
      cursor: pointer;

      &-icon {
        width: 70%;
        height: auto;
        fill: #fff;
      }
    }

    &-img {
      width: 100%;
      height: 130px;
      object-fit: cover;
      object-position: center;
      margin-bottom: 20px;
      cursor: pointer;
    }

    &-brand {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 10px;
      cursor: pointer;
      user-select: none;
      font-family: 'Merriweather';
    }

    &-product {
      font-size: 16px;
      margin-bottom: 10px;
      cursor: pointer;
      user-select: none;
      font-family: 'Oswald';
    }

    &-sign {
      font-size: 16px;
      font-weight: 600;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
