import axiosAPI from '.';

const createPurchase = async (data: any) => {
  return await axiosAPI.post('/p/purchase', data);
};

const readPurchase = async () => {
  return await axiosAPI.get('/p/purchase/my');
};

const readAllPurchase = async () => {
  return await axiosAPI.get('/p/purchase');
};

const updatePurchase = async (data: any) => {
  return await axiosAPI.put('/p/purchase', data);
};

const getCategoryMenu = async () => {
  return await axiosAPI.get('/p/category/menu');
};

const getCategorySubMenu = async () => {
  return await axiosAPI.get('/p/category/submenu');
};

const getProducts = async (data: any) => {
  return await axiosAPI.get(
    `/p/products/${data.id}?category=
    ${data.category ? data.category : 'empty'}
    ${data.search ? `&search=${data.search}` : ''}
    ${data.page ? `&page=${data.page}&limit=${data.limit}` : ''}`
  );
};

const getProduct = async (data: any) => {
  return await axiosAPI.get(`/p/product/${data}`);
};

const getSearch = async (data: any) => {
  return await axiosAPI.get(`/p/search?search=${data}`);
};

const favoriteProduct = async (data: any) => {
  return await axiosAPI.post('/p/product/favorite', data);
};

const myFavoriteProducts = async () => {
  return await axiosAPI.get('/p/favorite-products');
};

const readAllBrand = async () => {
  return await axiosAPI.get('/p/brand');
};

const readAllBanner = async () => {
  return await axiosAPI.get('/p/banner');
};

const readNotice = async () => {
  return await axiosAPI.get('/p/notice');
};

const createInquiry = async (data: any) => {
  return await axiosAPI.post('/p/inquiry', data);
};

const root = {
  getCategoryMenu,
  getProducts,
  getProduct,
  favoriteProduct,
  myFavoriteProducts,
  readAllBanner,
  readNotice,
  getCategorySubMenu,
  createPurchase,
  readPurchase,
  updatePurchase,
  readAllPurchase,
  readAllBrand,
  getSearch,
  createInquiry,
};

export default root;
