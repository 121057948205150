import axiosAPI from '.';

const signin = async (data: any) => {
  return await axiosAPI.post('/u/sign-in', data);
};

const postUser = async (data: any) => {
  return await axiosAPI.post('/u/user', data);
};

const putUser = async (data: any) => {
  return await axiosAPI.put('/u/user', data);
};

const putMyUser = async (data: any) => {
  return await axiosAPI.put('/u/user/my', data);
};

const getMyUser = async () => {
  return await axiosAPI.get('/u/user/my');
};

const root = {
  signin,
  postUser,
  putUser,
  putMyUser,
  getMyUser,
};

export default root;
