import { ReactChild } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';

type MyPageNavProps = {
  children: ReactChild | ReactChild[];
};

export default function MyPageNav({ children }: MyPageNavProps) {
  const location = useLocation();
  const history = useHistory();

  function title() {
    switch (location.pathname) {
      case '/order-history':
        return 'Order History';

      case '/favorite':
        return 'My Favorite';

      case '/profile':
        return 'Change My Profile';

      default:
        break;
    }
  }

  return (
    <Box>
      <div className={cn('left_panel')}>
        <span
          className={cn('left_panel-nav', {
            'left_panel-select': location.pathname === '/order-history',
          })}
          onClick={() => {
            history.push('/order-history');
          }}
        >
          Order History
        </span>

        <span
          className={cn('left_panel-nav', {
            'left_panel-select': location.pathname === '/favorite',
          })}
          onClick={() => {
            history.push('/favorite');
          }}
        >
          My Favorite
        </span>

        <span
          className={cn('left_panel-nav', {
            'left_panel-select': location.pathname === '/profile',
          })}
          onClick={() => {
            history.push('/profile');
          }}
        >
          Change My Profile
        </span>
      </div>

      <div className={cn('right_panel')}>
        <span className={cn('right_panel-title')}>{title()}</span>

        <div className={cn('right_panel-contents')}>{children}</div>
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 150px 350px;

  .left_panel {
    width: calc(100% - 900px);
    height: 100%;
    display: flex;
    flex-direction: column;

    &-nav {
      width: fit-content;
      height: fit-content;
      font-size: 18px;
      font-weight: 500;
      color: #8f8f8f;
      margin-bottom: 30px;
      user-select: none;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &-select {
      font-weight: 600;
      color: #000;
    }
  }

  .right_panel {
    width: 900px;
    height: 100%;
    display: flex;
    flex-direction: column;

    &-title {
      font-weight: 600;
      font-size: 36px;
      color: #333333;
      margin-bottom: 50px;
    }

    &-contents {
      width: 100%;
      height: fit-content;
    }
  }
`;
