import styled from 'styled-components';
import cn from 'classnames';
import { Add, Remove } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

import CheckBox from '../common/CheckBox3';
import tokenLib from '../../library/token.lib';
import { useState } from 'react';

type ProductsProps = {
  product_list: any[];
  category_sub_menu_list: any;
  category: any[];
  setCategory: (state: any[]) => void;
  search: string;
  setSearch: (state: string) => void;
};

export default function Products({
  product_list,
  category_sub_menu_list,
  category,
  setCategory,
  search,
  setSearch,
}: ProductsProps) {
  const history = useHistory();

  const [isMenu1, setIsMenu1] = useState(true);
  const [isMenu2, setIsMenu2] = useState(true);
  const [isMenu3, setIsMenu3] = useState(true);
  const [isMenu4, setIsMenu4] = useState(true);

  const handlePush = (path: string) => {
    history.push(path);
  };

  const handleType = (type: string) => {
    switch (type) {
      case 'new':
        return 'New';

      case 'best':
        return 'Best';

      case 'sale':
        return 'Sale';

      default:
        break;
    }
  };

  return (
    <Box>
      <div className={cn('left_panel')}>
        <input
          className={cn('left_panel-input')}
          placeholder='Product and brand search'
          value={search}
          onChange={(value) => setSearch(value.currentTarget.value)}
        />

        {category_sub_menu_list ? (
          <>
            <div className={cn('menu_box')}>
              <div
                className={cn('menu_box-top')}
                onClick={() => setIsMenu1(!isMenu1)}
              >
                <span className={cn('menu_box-top-text')}>Brand</span>

                <span className={cn('menu_box-top-icon')}>
                  {isMenu1 ? <Remove /> : <Add />}
                </span>
              </div>

              <div className={cn('menu_box-list')}>
                {isMenu1 ? (
                  category_sub_menu_list.brand.map(
                    (data: any, index: number) => {
                      return (
                        <CheckBox
                          key={index}
                          className='menu_box-list-item'
                          onClick={() => {
                            if (category.includes(data.id)) {
                              setCategory(
                                category.filter((c: any) => c !== data.id)
                              );
                            } else {
                              setCategory([...category, data.id]);
                            }
                          }}
                        >
                          {data.title}
                        </CheckBox>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className={cn('menu_box')}>
              <div
                className={cn('menu_box-top')}
                onClick={() => setIsMenu2(!isMenu2)}
              >
                <span className={cn('menu_box-top-text')}>Product Line</span>

                <span className={cn('menu_box-top-icon')}>
                  {isMenu2 ? <Remove /> : <Add />}
                </span>
              </div>

              <div className={cn('menu_box-list')}>
                {isMenu2 ? (
                  category_sub_menu_list.product_line.map(
                    (data: any, index: number) => {
                      return (
                        <CheckBox
                          key={index}
                          className='menu_box-list-item'
                          onClick={() => {
                            if (category.includes(data.id)) {
                              setCategory(
                                category.filter((c: any) => c !== data.id)
                              );
                            } else {
                              setCategory([...category, data.id]);
                            }
                          }}
                        >
                          {data.title}
                        </CheckBox>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className={cn('menu_box')}>
              <div
                className={cn('menu_box-top')}
                onClick={() => setIsMenu3(!isMenu3)}
              >
                <span className={cn('menu_box-top-text')}>Type</span>

                <span className={cn('menu_box-top-icon')}>
                  {isMenu3 ? <Remove /> : <Add />}
                </span>
              </div>

              <div className={cn('menu_box-list')}>
                {isMenu3 ? (
                  category_sub_menu_list.type.map(
                    (data: any, index: number) => {
                      return (
                        <CheckBox
                          key={index}
                          className='menu_box-list-item'
                          onClick={() => {
                            if (category.includes(data.id)) {
                              setCategory(
                                category.filter((c: any) => c !== data.id)
                              );
                            } else {
                              setCategory([...category, data.id]);
                            }
                          }}
                        >
                          {data.title}
                        </CheckBox>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className={cn('menu_box')}>
              <div
                className={cn('menu_box-top')}
                onClick={() => setIsMenu4(!isMenu4)}
              >
                <span className={cn('menu_box-top-text')}>Benefit</span>

                <span className={cn('menu_box-top-icon')}>
                  {isMenu4 ? <Remove /> : <Add />}
                </span>
              </div>

              <div className={cn('menu_box-list')}>
                {isMenu4 ? (
                  category_sub_menu_list.benefit.map(
                    (data: any, index: number) => {
                      return (
                        <CheckBox
                          key={index}
                          className='menu_box-list-item'
                          onClick={() => {
                            if (category.includes(data.id)) {
                              setCategory(
                                category.filter((c: any) => c !== data.id)
                              );
                            } else {
                              setCategory([...category, data.id]);
                            }
                          }}
                        >
                          {data.title}
                        </CheckBox>
                      );
                    }
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className={cn('right_panel')}>
        {/* <div className={cn('select_box')}>
          <span className={cn('select_box-text')}>Price Low to High</span>

          <ArrowDropDown className={cn('select_box-icon')} />
        </div> */}

        <div className={cn('list')}>
          {product_list.map((data: any, index) => {
            if (!data.image_list.length) return <></>;

            return (
              <div key={index} className={cn('list-item')}>
                {data.type === 'none' ? (
                  <></>
                ) : (
                  <span className={cn(`list-item-${data.type}`)}>
                    {handleType(data.type)}
                  </span>
                )}

                <img
                  className={cn('list-item-img')}
                  src={data.image_list[0].image}
                  alt='item_img'
                  onClick={() => handlePush(`/product/${data.id}`)}
                />

                <span
                  className={cn('list-item-brand')}
                  onClick={() => handlePush(`/product/${data.id}`)}
                >
                  {data.brand.name}
                </span>

                <span
                  className={cn('list-item-product')}
                  onClick={() => handlePush(`/product/${data.id}`)}
                >
                  {data.name}
                </span>

                <span
                  className={cn('list-item-sign')}
                  onClick={() => {
                    if (!tokenLib.getRefreshToken()) handlePush('/sign');
                  }}
                >
                  {!tokenLib.getRefreshToken()
                    ? 'Login or Register to buy'
                    : tokenLib.getCountry() === 'guam'
                    ? `$${parseFloat(
                        data.guam_original_price
                      ).toLocaleString()}`
                    : `$${parseFloat(
                        data.saipan_original_price
                      ).toLocaleString()}`}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 100px 150px;

  .left_panel {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    margin-right: auto;

    &-input {
      width: 100%;
      height: 50px;
      border: 1px solid #acacac;
      padding: 15px;
      margin-bottom: 15px;
      outline: none;

      &::placeholder {
        color: #acacac;
      }
    }
  }

  .right_panel {
    width: 75%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: 0;
  }

  .menu_box {
    width: 250px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 1.2rem 0;
    border-bottom: 1px solid #c4c4c4;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    &-top {
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      margin-bottom: 1rem;
      user-select: none;
      cursor: pointer;

      &-text {
        font-weight: 600;
        user-select: none;
      }

      &-icon {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &-list {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-item {
        margin: 0.2rem 0;
      }
    }
  }

  .select_box {
    width: 300px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    border: 1px solid #292929;
    padding: 0 1rem;
    margin-bottom: 30px;
    cursor: pointer;

    &-text {
      cursor: pointer;
      user-select: none;
    }

    &-icon {
      width: auto;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .list {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(auto-fill, 490px);
    row-gap: 40px;
    column-gap: 20px;

    &-item {
      width: 100%;
      height: 490px;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border: 1px solid #dbdbdb;

      &-best,
      &-new,
      &-sale {
        width: fit-content;
        height: fit-content;
        position: absolute;
        top: 20px;
        left: 20px;
        padding: 3px 8px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        color: #fff;
      }

      &-best {
        background-color: #88c764;
      }

      &-new {
        background-color: #f68b50;
      }

      &-sale {
        background-color: #f15a53;
      }

      &-img {
        width: 100%;
        height: 300px;
        object-fit: contain;
        object-position: center;
        margin-bottom: 20px;
        cursor: pointer;
      }

      &-brand {
        font-weight: 600;
        margin-bottom: 10px;
        cursor: pointer;
        user-select: none;
        font-family: 'Merriweather';
      }

      &-product {
        font-size: 20px;
        margin-bottom: 10px;
        cursor: pointer;
        user-select: none;
        font-family: 'Oswald';
      }

      &-sign {
        font-size: 18px;
        font-weight: 600;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
`;
