import { createAction } from 'redux-actions';

import { Payload } from '../reducer/user.reducer';

export const SIGN_IN_REQUEST = 'user/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'user/SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'user/SIGN_IN_FAILURE';
export const handleSignInRequest = createAction(
  SIGN_IN_REQUEST,
  (data: any, success: Payload['success'], failure: Payload['failure']) => ({
    data,
    success,
    failure,
  })
);

export const USER_REQUEST = 'user/USER_REQUEST';
export const USER_SUCCESS = 'user/USER_SUCCESS';
export const USER_FAILURE = 'user/USER_FAILURE';
export const handleUserRequest = createAction(
  USER_REQUEST,
  (data: any, success: Payload['success'], failure: Payload['failure']) => ({
    data,
    success,
    failure,
  })
);

export const USER_DETAIL_REQUEST = 'user/USER_DETAIL_REQUEST';
export const USER_DETAIL_SUCCESS = 'user/USER_DETAIL_SUCCESS';
export const USER_DETAIL_FAILURE = 'user/USER_DETAIL_FAILURE';
export const handleUserDetailRequest = createAction(
  USER_DETAIL_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const UPDATE_USERINFO_REQUEST = 'user/UPDATE_USERINFO_REQUEST';
export const UPDATE_USERINFO_SUCCESS = 'user/UPDATE_USERINFO_SUCCESS';
export const UPDATE_USERINFO_FAILURE = 'user/UPDATE_USERINFO_FAILURE';
export const handleUpdateUserInfoRequest = createAction(
  UPDATE_USERINFO_REQUEST,
  (data: any, success: Payload['success'], failure: Payload['failure']) => ({
    data,
    success,
    failure,
  })
);
