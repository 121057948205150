import styled from 'styled-components';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import IMG_NOT_FOUND from '../../static/images/image not found.png';

type ShopByBrandProps = {
  brand_list: any[];
};

export default function ShopByBrand({ brand_list }: ShopByBrandProps) {
  const history = useHistory();

  const handlePush = (path: string) => {
    history.push(path);
  };

  return (
    <Box>
      <div className={cn('wrap')}>
        {brand_list.map((brand: any) => {
          return (
            <div
              className={cn('item')}
              onClick={() => handlePush(`/products/${brand.category_id}`)}
            >
              <img
                className={cn('item-img')}
                src={brand.image ? brand.image : IMG_NOT_FOUND}
                style={{ objectFit: brand.image ? 'cover' : 'contain' }}
                alt='brand_logo'
                draggable={false}
              />

              <span className={cn('item-name')}>{brand.name}</span>
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  padding: 100px 150px;

  .wrap {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(auto-fill, 240px);
    row-gap: 40px;
    column-gap: 20px;

    .item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border: 1px solid #dee2e6;
      cursor: pointer;

      &-img {
        width: 100%;
        height: 200px;
        object-fit: cover;
        object-position: center;
      }

      &-name {
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #dee2e6;
        font-weight: 500;
        user-select: none;
      }
    }
  }
`;
