import { ReactChild, useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import LOGO_IMG from '../../static/images/logo.png';
import ICON_1 from '../../static/images/ic_mypage.svg';
import ICON_2 from '../../static/images/ic_shopping.svg';
import ICON_3 from '../../static/images/ic_login.svg';
import ICON_4 from '../../static/images/ic_order.svg';
import ICON_5 from '../../static/images/ic_heart.png';
import { Add, Chat, Close, Menu, Remove, Search } from '@mui/icons-material';
import tokenLib from '../../library/token.lib';
import Color from 'color';

type PageTemplateProps = {
  category_menu_list: any[];
  notice?: string;
  children: ReactChild | ReactChild[];
  search: string;
  setSearch: (state: string) => void;
  search_list: any;
  handleSearch: () => void;
  cellphone: string;
  setCellphone: (state: string) => void;
  email: string;
  setEmail: (state: string) => void;
  inquiry: string;
  setInquiry: (state: string) => void;
  isModal: boolean;
  setIsModal: (state: boolean) => void;
  handleInquiry: () => void;
};

export default function PageTemplate({
  category_menu_list,
  handleSearch,
  search,
  search_list,
  setSearch,
  notice,
  cellphone,
  email,
  handleInquiry,
  inquiry,
  isModal,
  setCellphone,
  setEmail,
  setInquiry,
  setIsModal,
  children,
}: PageTemplateProps) {
  const history = useHistory();

  const [isMenu, setIsMenu] = useState(false);

  const [isSearch, setIsSearch] = useState(false);

  const handlePush = (path: string) => {
    history.push(path);

    setIsMenu(false);
  };

  const [isSub1, setIsSub1] = useState(false);
  const [isSub2, setIsSub2] = useState(false);
  const [isSub3, setIsSub3] = useState(false);
  const [isSub4, setIsSub4] = useState(false);
  const [isSub5, setIsSub5] = useState(false);

  const handleGetSub = useCallback(
    (name: string) => {
      switch (name) {
        case 'hair_care':
          return isSub1;

        case 'hair_color':
          return isSub2;

        case 'tools_appliances':
          return isSub3;

        case 'salon_essentials':
          return isSub4;

        case 'skin_body':
          return isSub5;

        default:
          return false;
      }
    },
    [isSub1, isSub2, isSub3, isSub4, isSub5]
  );

  const handleSub = useCallback(
    (prop: boolean, type: '1' | '2' | '3' | '4' | '5') => {
      switch (type) {
        case '1':
          setIsSub1(prop);

          break;

        case '2':
          setIsSub2(prop);

          break;

        case '3':
          setIsSub3(prop);

          break;

        case '4':
          setIsSub4(prop);

          break;

        case '5':
          setIsSub5(prop);

          break;

        default:
          break;
      }
    },
    [isSub1, isSub2, isSub3, isSub4, isSub5]
  );

  useEffect(() => {
    if (!isMenu) {
      setIsSub1(false);
      setIsSub2(false);
      setIsSub3(false);
      setIsSub4(false);
      setIsSub5(false);
    }
  }, [isMenu]);

  return (
    <>
      <Box>
        <div className={cn('header')}>
          <div className={cn('header-1')}>
            <img
              className={cn('header-1-logo')}
              src={LOGO_IMG}
              alt='logo'
              draggable={false}
              onClick={() => handlePush('/mobile')}
            />

            <div
              className={cn('header-1-icon_wrap')}
              onClick={() => setIsMenu(true)}
            >
              <Menu className={cn('header-1-icon_wrap-icon')} />
            </div>
          </div>

          {notice ? <div className={cn('header-2')}>{notice}</div> : <></>}
        </div>

        <div className={cn('content')}>{children}</div>

        <div className={cn('footer')}>
          <div className={cn('footer-wrap')}>
            <img
              className={cn('footer-wrap-img')}
              src={LOGO_IMG}
              alt='logo'
              draggable={false}
            />

            <div className={cn('footer-wrap-text_wrap')}>
              <span className={cn('footer-wrap-text_wrap-text')}>
                Email: System4uguam@gmail.com
              </span>

              <span className={cn('footer-wrap-text_wrap-text')}>
                Phone: +1 671 646 4909/ +1 671 688 8780
              </span>

              <span className={cn('footer-wrap-text_wrap-text')}>
                Address: 719 S. Marine Corps Drive 104 Tamuning, Guam, 96913
              </span>

              <span className={cn('footer-wrap-text_wrap-text')}>
                Mailing Address: 425 Chalan San Antonio PMB 321 Tamuning, Guam
                96913
              </span>

              <div className={cn('footer-wrap-text_wrap-line')} />

              <span className={cn('footer-wrap-text_wrap-text')}>
                Copyrights © 2017 All Rights Reserved by SYSTEM4U
              </span>
            </div>
          </div>
        </div>

        {isMenu ? (
          <div className={cn('menu')}>
            <div className={cn('menu-header')}>
              <div className={cn('menu-header-1')}>
                <img
                  className={cn('menu-header-1-logo')}
                  src={LOGO_IMG}
                  alt='logo'
                  draggable={false}
                  onClick={() => handlePush('/mobile')}
                />

                <div
                  className={cn('menu-header-1-icon_wrap')}
                  onClick={() => setIsMenu(false)}
                >
                  <Close className={cn('menu-header-1-icon_wrap-icon')} />
                </div>
              </div>
            </div>

            <div className={cn('menu-contents')}>
              <div className={cn('menu-contents-search')}>
                <input
                  className={cn('menu-contents-search-input')}
                  placeholder='Search'
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setIsSearch(false);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      if (search === '') return;

                      handleSearch();
                      setIsSearch(true);
                    }
                  }}
                />

                <Search
                  className={cn('menu-contents-search-icon')}
                  onClick={() => {
                    if (search === '') return;

                    handleSearch();
                    setIsSearch(true);
                  }}
                />
              </div>

              {tokenLib.getRefreshToken() ? (
                <>
                  <div
                    className={cn('menu-contents-text')}
                    onClick={() => handlePush('/mobile/purchase')}
                  >
                    <img
                      className={cn('menu-contents-text-icon')}
                      src={ICON_2}
                      alt='icon'
                      draggable={false}
                    />
                    Shopping Bag
                  </div>

                  <div
                    className={cn('menu-contents-text')}
                    onClick={() => handlePush('/mobile/profile')}
                  >
                    <img
                      className={cn('menu-contents-text-icon')}
                      src={ICON_1}
                      alt='icon'
                      draggable={false}
                    />
                    My Profile
                  </div>

                  <div
                    className={cn('menu-contents-text')}
                    onClick={() => handlePush('/mobile/order-history')}
                  >
                    <img
                      className={cn('menu-contents-text-icon')}
                      src={ICON_4}
                      alt='icon'
                      draggable={false}
                    />
                    Order History
                  </div>

                  <div
                    className={cn('menu-contents-text')}
                    onClick={() => handlePush('/mobile/favorite')}
                  >
                    <img
                      className={cn('menu-contents-text-icon')}
                      src={ICON_5}
                      alt='icon'
                      draggable={false}
                    />
                    My Favorite
                  </div>
                </>
              ) : (
                <></>
              )}

              <div
                className={cn('menu-contents-text')}
                style={{
                  borderBottom: '1px solid #ced4da',
                  marginBottom: '40px',
                }}
                onClick={() => {
                  tokenLib.removeToken('ALL');

                  handlePush('/mobile/sign');
                }}
              >
                <img
                  className={cn('menu-contents-text-icon')}
                  src={ICON_3}
                  alt='icon'
                  draggable={false}
                />

                {tokenLib.getRefreshToken() ? 'Logout' : 'Login / Sign Up'}
              </div>

              {isSearch && search_list ? (
                <>
                  <span className={cn('menu-contents-title')}>Product</span>

                  {search_list.product_list.map((data: any, index: number) => {
                    return (
                      <span
                        key={index}
                        className={cn('menu-contents-text')}
                        onClick={() => {
                          history.push(`/mobile/product/${data.id}`);
                          setIsSearch(false);
                          setIsMenu(false);
                          setSearch('');
                        }}
                      >
                        {data.image_list.length ? (
                          <img
                            className={cn('menu-contents-text-icon')}
                            src={data.image_list[0].image}
                            alt='icon'
                            draggable={false}
                          />
                        ) : (
                          <></>
                        )}

                        {data.name}
                      </span>
                    );
                  })}
                </>
              ) : (
                <></>
              )}

              {isSearch && search_list ? (
                <>
                  <span className={cn('menu-contents-title')}>Brand</span>

                  {search_list.brand_list.map((data: any, index: number) => {
                    return (
                      <span
                        key={index}
                        className={cn('menu-contents-text')}
                        onClick={() => {
                          history.push(`/mobile/products/${data.category_id}`);
                          setIsSearch(false);
                          setIsMenu(false);
                          setSearch('');
                        }}
                      >
                        {data.image ? (
                          <img
                            className={cn('menu-contents-text-icon')}
                            src={data.image}
                            alt='icon'
                            draggable={false}
                          />
                        ) : (
                          <></>
                        )}

                        {data.name}
                      </span>
                    );
                  })}
                </>
              ) : (
                <></>
              )}

              {!isSearch &&
                category_menu_list &&
                category_menu_list.map((data, index) => {
                  return (
                    <div key={index} className={cn('menu-contents-category')}>
                      <div
                        className={cn('menu-contents-category-wrap')}
                        onClick={() => {
                          if (data.sub_category_list) {
                            switch (data.name) {
                              case 'hair_care':
                                handleSub(!isSub1, '1');

                                break;

                              case 'hair_color':
                                handleSub(!isSub2, '2');

                                break;

                              case 'tools_appliances':
                                handleSub(!isSub3, '3');

                                break;

                              case 'salon_essentials':
                                handleSub(!isSub4, '4');

                                break;

                              case 'skin_body':
                                handleSub(!isSub5, '5');

                                break;

                              default:
                                break;
                            }
                          } else {
                            handlePush(
                              data.title === 'Shop By Brand'
                                ? '/mobile/shopbybrand'
                                : `/mobile/products/${data.id}`
                            );
                          }
                        }}
                      >
                        <span
                          className={cn('menu-contents-category-wrap-text')}
                        >
                          {data.title}
                        </span>

                        {data.sub_category_list ? (
                          <>{handleGetSub(data.name) ? <Remove /> : <Add />}</>
                        ) : (
                          <></>
                        )}
                      </div>

                      {data.sub_category_list && handleGetSub(data.name) ? (
                        <>
                          <div
                            className={cn(
                              'menu-contents-category-sub_category'
                            )}
                          >
                            {data.sub_category_list.map(
                              (sc: any, index: number) => {
                                return (
                                  <span
                                    key={index}
                                    className={cn(
                                      'menu-contents-category-sub_category-text'
                                    )}
                                    onClick={(e) => {
                                      e.stopPropagation();

                                      handlePush(`/mobile/products/${sc.id}`);
                                    }}
                                  >
                                    {sc.title}
                                  </span>
                                );
                              }
                            )}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className={cn('message')} onClick={() => setIsModal(true)}>
          <span className={cn('message-text')}>Ask Us!</span>

          <Chat className={cn('message-icon')} />
        </div>

        {isModal ? (
          <div className={cn('modal')}>
            <Close
              className={cn('modal-icon')}
              onClick={() => setIsModal(false)}
            />

            <div className={cn('modal-text')}>
              Hawaii adai,
              <br />
              Please ask us anything!
              <br />
              -or you can also call us, 671-646-4909
            </div>

            <div className={cn('modal-wrap')}>
              <span className={cn('modal-wrap-text')}>Cellphone number</span>

              <input
                className={cn('modal-wrap-input')}
                placeholder='cellphone...'
                value={cellphone}
                onChange={(e) => setCellphone(e.target.value)}
              />
            </div>

            <div className={cn('modal-wrap')}>
              <span className={cn('modal-wrap-text')}>Email(Optional)</span>

              <input
                className={cn('modal-wrap-input')}
                placeholder='email...'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className={cn('modal-wrap')}>
              <span className={cn('modal-wrap-text')}>
                Inquiry(Any language is fine!)
              </span>

              <textarea
                className={cn('modal-wrap-textarea')}
                placeholder='inquiry...'
                value={inquiry}
                onChange={(e) => setInquiry(e.target.value)}
              />
            </div>

            <button className={cn('modal-button')} onClick={handleInquiry}>
              SUBMIT
            </button>
          </div>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
}

const Box = styled.div`
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;

    &-1 {
      width: 100%;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      padding: 30px 15px;
      border-bottom: 1px solid ${(prop) => prop.theme.gray};

      &-logo {
        width: auto;
        height: 35px;
        cursor: pointer;
      }

      &-icon_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;

        &-icon {
          width: auto;
          height: 50px;
        }
      }
    }

    &-2 {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 13px 20px;
      line-height: 20px;
      font-size: 12px;
      word-break: keep-all;
      color: ${(prop) => prop.theme.white};
      background-color: ${(prop) => prop.theme.primary};
    }
  }

  .content {
    width: 100%;
    height: fit-content;
  }

  .footer {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    background-color: ${(prop) => prop.theme.primary};

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 50px 15px;

      &-img {
        width: 160px;
        height: auto;
        margin-bottom: 30px;
      }

      &-text_wrap {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &-text {
          font-size: 15px;
          font-weight: 300;
          margin: 3px 0;
          color: ${(prop) => prop.theme.white};
        }

        &-line {
          width: 100%;
          height: 1px;
          background-color: ${(prop) => prop.theme.white};
          margin: 20px 0;
        }
      }
    }
  }

  .menu {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #f3f3f3;
    overflow: auto;
    z-index: 2;

    &-header {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-1 {
        width: 100%;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        padding: 30px 15px;
        border-bottom: 1px solid ${(prop) => prop.theme.gray};

        &-logo {
          width: auto;
          height: 35px;
          cursor: pointer;
        }

        &-icon_wrap {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;

          &-icon {
            width: auto;
            height: 50px;
          }
        }
      }
    }

    &-contents {
      width: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      padding: 25px 15px;

      &-search {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border: 1px solid #000;
        border-radius: 5px;
        margin: 0 auto 40px 0;
        padding: 0 10px;

        &-input {
          width: calc(100% - 24px);
          height: 100%;
          outline: none;
          border: 0;
          background-color: transparent;

          &::placeholder {
            color: #c4c4c4;
          }
        }

        &-icon {
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }

      &-title {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 24px;
        color: #c4c4c4;
        margin-bottom: 40px;
      }

      &-text {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 40px;

        &:last-child {
          padding-bottom: 0;
        }

        &-icon {
          width: 24px;
          height: 24px;
          object-fit: cover;
          object-position: center;
          margin-right: 10px;
        }
      }

      &-category {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        &-wrap {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          padding-bottom: 40px;

          &-text {
            width: fit-content;
            height: fit-content;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 20px;
            font-weight: 600;
          }
        }

        &-sub_category {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;

          &-text {
            width: 100%;
            height: fit-content;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            padding-bottom: 40px;
            color: ${Color('#adb5bd').blacken(0.5).hex()};
          }
        }
      }
    }
  }

  .message {
    width: 50px;
    height: 50px;
    position: fixed;
    right: 10px;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 0 1px #dadcdf;
    cursor: pointer;
    z-index: 1;

    &-text {
      width: 60px;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      bottom: calc(100% + 10px);
      transform: translateX(-50%);
      font-size: 13px;
      font-weight: 500;
      color: #88c763;
    }

    &-icon {
      fill: #88c763;
    }
  }

  .modal {
    width: 80vw;
    height: 80vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    border-radius: 4px;
    padding: 30px;
    box-shadow: 0 0 0 1px #dadcdf;
    overflow: auto;
    z-index: 1;

    &-icon {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    &-text {
      font-size: 15px;
      line-height: 1.5;
      margin-bottom: 20px;
    }

    &-wrap {
      width: 100%;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      margin-bottom: 20px;

      &-text {
        color: #88c763;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      &-input {
        width: 100%;
        height: 40px;
        padding: 15px;
        outline: none;
        border: 1px solid #acacac;
        font-size: 13px;

        &::placeholder {
          color: #acacac;
        }
      }

      &-textarea {
        width: 100%;
        height: 150px;
        padding: 15px;
        outline: none;
        border: 1px solid #acacac;
        resize: none;
        font-size: 13px;

        &::placeholder {
          color: #acacac;
        }
      }
    }

    &-button {
      width: 100%;
      height: fit-content;
      padding: 14px;
      font-size: 15px;
      font-weight: 500;
      color: #fff;
      background: #f68b50;
      border: 2px solid #e3580b;
      border-radius: 5px;
      margin: auto auto 0 auto;
      cursor: pointer;
    }
  }
`;
