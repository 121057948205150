import { useState, useEffect, Fragment } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Select from 'react-select';
import { quantityOptions } from '../../containers/product';
import tokenLib from '../../library/token.lib';
import { useHistory } from 'react-router-dom';

type ProductProps = {
  product: any;
  selectSize: any;
  setSelectSize: (state: any) => void;
  selectQuantity: any;
  handleQuantity: (props: any) => void;
  selectBox: any;
  handleFavorite: () => void;
  isSelect: 'none' | 'quantity' | 'box';
  handlePurchase: (type: 'bag' | 'buy') => void;
  selectColor: any;
  setSelectColor: (state: any) => void;
};

export default function Product({
  product,
  selectSize,
  setSelectSize,
  selectQuantity,
  selectBox,
  handleQuantity,
  handleFavorite,
  isSelect,
  handlePurchase,
  selectColor,
  setSelectColor,
}: ProductProps) {
  const history = useHistory();

  const [selectImg, setSelectImg] = useState<any>(null);
  const [price, setPrice] = useState(0);
  const [prevPrice, setPrevPrice] = useState(0);

  const handleSentence = (text: string) => {
    return text
      .split('\n')
      .map((text: string, index: number, list: string[]) => {
        if (index === list.length - 1) {
          return text;
        }

        return (
          <Fragment key={index}>
            {text}
            <br />
          </Fragment>
        );
      });
  };

  const handlePrice = () => {
    if (!selectSize) return;

    let total_price = 0;

    const guam_discount_price =
      ((parseFloat(product.guam_original_price) +
        parseFloat(selectSize.guam_quantity_price)) *
        parseFloat(selectSize.guam_discount_percent)) /
      100;

    const saipan_discount_price =
      ((parseFloat(product.saipan_original_price) +
        parseFloat(selectSize.saipan_quantity_price)) *
        parseFloat(selectSize.saipan_discount_percent)) /
      100;

    if (tokenLib.getCountry() === 'guam') {
      setPrevPrice(
        (parseFloat(product.guam_original_price) +
          parseFloat(selectSize.guam_quantity_price)) *
          selectQuantity.value
      );

      switch (isSelect) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.guam_original_price) +
                  parseFloat(selectSize.guam_quantity_price) -
                  guam_discount_price) *
                selectQuantity.value
              : (parseFloat(product.guam_original_price) +
                  parseFloat(selectSize.guam_quantity_price)) *
                selectQuantity.value;

          break;

        case 'box':
          total_price =
            (parseFloat(product.guam_box_price) +
              parseFloat(selectSize.guam_box_price)) *
            selectBox.value;

          break;

        default:
          total_price =
            parseFloat(product.guam_original_price) +
            parseFloat(selectSize.guam_quantity_price);

          break;
      }
    } else {
      setPrevPrice(
        (parseFloat(product.saipan_original_price) +
          parseFloat(selectSize.saipan_quantity_price)) *
          selectQuantity.value
      );

      switch (isSelect) {
        case 'quantity':
          total_price =
            product.type === 'sale'
              ? (parseFloat(product.saipan_original_price) +
                  parseFloat(selectSize.saipan_quantity_price) -
                  saipan_discount_price) *
                selectQuantity.value
              : (parseFloat(product.saipan_original_price) +
                  parseFloat(selectSize.saipan_quantity_price)) *
                selectQuantity.value;

          break;

        case 'box':
          total_price =
            (parseFloat(product.saipan_box_price) +
              parseFloat(selectSize.saipan_box_price)) *
            selectBox.value;

          break;

        default:
          total_price =
            parseFloat(product.saipan_original_price) +
            parseFloat(selectSize.saipan_quantity_price);

          break;
      }
    }

    setPrice(total_price);
  };

  useEffect(() => {
    setSelectImg(product ? product.image_list[0] : '');
  }, [product]);

  useEffect(() => {
    handlePrice();
  }, [product, selectSize, isSelect, selectQuantity]);

  return (
    <Box>
      <div className={cn('info')}>
        <div className={cn('info-left_panel')}>
          <img
            className={cn('info-left_panel-img')}
            src={selectImg ? selectImg.image : ''}
            alt='product_img'
          />

          <div className={cn('info-left_panel-imgs')}>
            {selectImg &&
              product.image_list.map((data: any, index: number) => {
                return (
                  <img
                    key={index}
                    className={cn('info-left_panel-imgs-item')}
                    style={{
                      border:
                        data.id === selectImg.id ? 'solid 2px #f68b50' : '',
                    }}
                    src={data.image}
                    alt='product_img'
                    onClick={() => setSelectImg(data)}
                  />
                );
              })}
          </div>
        </div>

        <div
          className={cn('info-right_panel')}
          style={{ height: product.product_type !== 'default' ? 'auto' : '' }}
        >
          <span className={cn('info-right_panel-product')}>{product.name}</span>

          <span className={cn('info-right_panel-brand')}>
            {product.brand.name}
          </span>

          <span className={cn('info-right_panel-quantity')}>Quantity</span>

          <Select
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderColor: '#e3580b',
                backgroundColor: '#f68b50',
              }),
            }}
            className={cn('info-right_panel-select_box')}
            options={quantityOptions}
            value={selectQuantity}
            onChange={handleQuantity}
          />

          {product.product_type !== 'default' ? (
            <>
              <div className={cn('info-right_panel-dye')}>
                {product.color_list.map((data: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className={cn('info-right_panel-dye-item', {
                        'info-right_panel-dye-item-select':
                          selectColor === data.id,
                      })}
                      onClick={() => setSelectColor(data.id)}
                    >
                      <img
                        className={cn('info-right_panel-dye-item-img')}
                        src={`https://system4u.s3.ap-northeast-2.amazonaws.com/production/product/${product.id}/color/${data.name}.jpeg`}
                        alt='color_img'
                        draggable={false}
                      />

                      <span
                        className={cn('info-right_panel-dye-item-name', {
                          'info-right_panel-dye-item-select-name':
                            selectColor === data.id,
                        })}
                      >
                        {data.name}
                      </span>

                      <span
                        className={cn('info-right_panel-dye-item-code', {
                          'info-right_panel-dye-item-select-code':
                            selectColor === data.id,
                        })}
                      >
                        {data.code}
                      </span>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <></>
          )}

          <span className={cn('info-right_panel-size')}>Size</span>

          <div className={cn('info-right_panel-size_wrap')}>
            {selectSize &&
              product.size_list.map((data: any, index: number) => {
                return (
                  <span
                    key={index}
                    className={cn('info-right_panel-size_wrap-item')}
                    style={{
                      border:
                        data.id === selectSize.id ? 'solid 1px #f68b50' : '',
                      backgroundColor:
                        data.id === selectSize.id ? '#f68b50' : '',
                      color: data.id === selectSize.id ? '#fff' : '',
                    }}
                    onClick={() => setSelectSize(data)}
                  >
                    {data.measurement === 'ml'
                      ? `${data.size} ml`
                      : `${data.size} fl oz`}
                  </span>
                );
              })}
          </div>

          {!tokenLib.getRefreshToken() ? (
            <span
              className={cn(
                'info-right_panel-price',
                'info-right_panel-price-sign'
              )}
              onClick={() => {
                history.push('/sign');
              }}
            >
              Login or Register to buy
            </span>
          ) : (
            <span className={cn('info-right_panel-price')}>
              {product.type === 'sale' && prevPrice !== price ? (
                <span className={cn('info-right_panel-price-discount_price')}>
                  ${prevPrice.toLocaleString()}
                </span>
              ) : (
                <></>
              )}
              ${price.toLocaleString()}
            </span>
          )}

          <button
            className={cn('info-right_panel-btn1')}
            onClick={() => handlePurchase('buy')}
          >
            Buy
          </button>

          <button
            className={cn('info-right_panel-btn2')}
            onClick={() => handlePurchase('bag')}
          >
            Add to bag
          </button>

          <button
            className={cn('info-right_panel-btn2')}
            onClick={handleFavorite}
          >
            My Favorite{'<Save for Later>'}
          </button>
        </div>
      </div>

      <span className={cn('details_title')}>Details & Directions</span>

      <div className={cn('details_contents')}>
        {handleSentence(product.details)}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 0;

  .info {
    width: 1205px;
    height: fit-content;
    display: flex;
    flex-direction: row;
    margin: 0 auto;

    &-left_panel {
      width: 746px;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-img {
        width: 746px;
        height: 746px;
        object-fit: contain;
        object-position: center;
        border: 1px solid #c2c2c2;
        padding: 30px;
        margin-bottom: 24px;
      }

      &-imgs {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        &-item {
          width: 130px;
          height: 130px;
          object-fit: contain;
          object-position: center;
          border: 1px solid #c2c2c2;
          padding: 10px;
          margin-right: 24px;
          cursor: pointer;

          &:last-child {
            margin-right: auto;
          }
        }
      }
    }

    &-right_panel {
      width: calc(100% - 746px);
      height: 746px;
      display: flex;
      flex-direction: column;
      padding-left: 25px;

      &-product {
        font-size: 24px;
        margin-bottom: 10px;
        font-family: 'Oswald';
      }

      &-brand {
        font-weight: 600;
        color: #8f8f8f;
        margin-bottom: 30px;
        font-family: 'Merriweather';
      }

      &-quantity,
      &-box,
      &-size {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      &-select_box {
        width: 300px;
        height: 50px;
        margin-bottom: 20px;
      }

      &-size_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        margin-bottom: auto;

        &-item {
          width: fit-content;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0.5rem 1rem;
          border: 1px solid #000;
          user-select: none;
          cursor: pointer;
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &-dye {
        width: 300px;
        height: 160px;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        overflow: auto;
        padding: 5px;
        border: 1px solid #000;
        border-radius: 4px;

        &::-webkit-scrollbar {
          width: 0;
        }

        &-item {
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          flex-wrap: wrap;
          cursor: pointer;
          padding: 5px;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          &-select {
            background-color: #f68b50;

            &-name,
            &-code {
              color: #fff;
            }
          }

          &-img {
            width: 60px;
            height: 45px;
            object-fit: cover;
            object-position: center;
            margin-right: 10px;
          }

          &-name {
            width: calc(100% - 70px);
            height: fit-content;
            font-size: 13px;
            font-weight: 600;
            user-select: none;
          }

          &-code {
            width: calc(100% - 70px);
            height: fit-content;
            font-size: 13px;
            user-select: none;
          }
        }
      }

      &-price {
        display: flex;
        flex-direction: column;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 30px;

        &-discount_price {
          font-size: 18px;
          font-style: italic;
          text-decoration: line-through;
          font-weight: 500;
          color: #ff6b6b;
        }

        &-sign {
          font-size: 24px;
          text-decoration: underline;
          cursor: pointer;
          user-select: none;
          color: #fa5252;
        }
      }

      &-btn1 {
        width: 300px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #e3580b;
        border-radius: 5px;
        background-color: #f68b50;
        cursor: pointer;
        outline: none;
        font-weight: 600;
        color: #fff;
        margin-top: auto;
        margin-bottom: 10px;
      }

      &-btn2 {
        width: 300px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #000;
        border-radius: 5px;
        background-color: #fff;
        cursor: pointer;
        outline: none;
        font-weight: 600;

        &:last-child {
          margin-top: 10px;
          margin-bottom: 0;
        }
      }
    }
  }

  .details_title {
    width: 1205px;
    height: fit-content;
    font-size: 32px;
    font-weight: 500;
    margin-top: 100px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 20px;
    border-bottom: 1px solid #c2c2c2;
  }

  .details_contents {
    width: 1205px;
    height: fit-content;
    line-height: 1.5;
    margin: 0 auto;
  }
`;
