import { useState, useEffect } from 'react';
import crypto from 'crypto';

import Component from '../../components/sign';
import useInputs from '../../hooks/useInputs';
import { useDispatch } from 'react-redux';
import {
  handleSignInRequest,
  handleUpdateUserInfoRequest,
  handleUserRequest,
} from '../../redux/action/user.action';
import { useToasts } from 'react-toast-notifications';
import upload from '../../library/upload.lib';
import tokenLib from '../../library/token.lib';
import { handleSignInAxiosAPI } from '../../redux/api';
import { useHistory } from 'react-router-dom';

const signInInitialForm = {
  email: '',
  password: '',
};

export type SignInFormType = typeof signInInitialForm;

const signUpInitialForm = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  confirm_password: '',
  mobile: '',
  business_name: '',
  etc: '',
};

export type SignUpFormType = typeof signUpInitialForm;

export default function Sign() {
  const history = useHistory();
  const { addToast } = useToasts();

  const dispatch = useDispatch();

  const [isSign, setIsSign] = useState(false);

  const [isPurchased, setIsPurchased] = useState('none');

  const [licensedStudent, setLicensedStudent] = useState(false);
  const [salonBusiness, setSalonBusiness] = useState(false);
  const [other, setOther] = useState(true);

  const [isLoading, setIsLoading] = useState(false);

  // true = guam, false = saipan
  const [isCountry, setIsCountry] = useState(true);

  const [image, setImage] = useState<File[]>([]);

  const [signInForm, onChangeSignIn, resetSignIn] =
    useInputs(signInInitialForm);
  const [signUpForm, onChangeSignUp, resetSignUp] =
    useInputs(signUpInitialForm);

  const handleIsSign = (state: boolean) => {
    setIsSign(state);
  };

  const handleCustomerType = () => {
    if (licensedStudent) {
      return {
        text: 'Licensed Professional OR Student',
        type: 'wholesale',
      };
    }

    if (salonBusiness) {
      return {
        text: 'Salon / Business Owner',
        type: 'salon',
      };
    }

    if (other) {
      return {
        text: 'Other',
        type: 'general',
      };
    }

    return {
      text: 'Other',
      type: 'general',
    };
  };

  const handleSignIn = () => {
    if (signInForm.email === '' || signInForm.password === '') {
      addToast('Please enter your email or password.', {
        appearance: 'warning',
      });

      return;
    }

    function success(response: any) {
      tokenLib.setAccessToken(
        response.accessToken,
        response.access_level,
        response.country
      );
      tokenLib.setRefreshToken(response.refreshToken);

      handleSignInAxiosAPI(response.accessToken);

      history.push('/');
    }

    function failure(error: any) {
      const { message } = error;

      addToast(message, {
        appearance: 'error',
      });
    }

    dispatch(
      handleSignInRequest(
        {
          email: signInForm.email,
          password: crypto
            .createHash('sha512')
            .update(signInForm.password)
            .digest('hex'),
        },
        success,
        failure
      )
    );
  };

  const handleSignUp = () => {
    if (isPurchased === 'none') {
      addToast('Please make sure you have never purchased from SYSTEM4U.', {
        appearance: 'warning',
      });

      return;
    }

    if (signUpForm.password !== signUpForm.confirm_password) {
      addToast('Passwords do not match.', { appearance: 'warning' });

      return;
    }

    setIsLoading(true);

    async function success(response: any) {
      function successUpdate() {
        addToast('Success Sign up!', { appearance: 'success' });

        setIsLoading(false);
        setIsSign(false);

        return;
      }

      function failureUpdate() {}

      let img = '';

      if (image.length) {
        await upload(
          image[0],
          `${process.env.REACT_APP_ENVIRONMENT}/user/${response.id}`
        )
          .then((s3_response) => {
            img = `https://system4u.s3.ap-northeast-2.amazonaws.com/${process.env.REACT_APP_ENVIRONMENT}/user/${response.id}/${s3_response.timestamp}.jpeg`;
          })
          .catch((error) => {
            console.error(error);
          });
      }

      dispatch(
        handleUpdateUserInfoRequest(
          { id: response.id, image: img },
          successUpdate,
          failureUpdate
        )
      );
    }

    function failure(error: any) {
      if (error.status === 409) {
        addToast(error.message, { appearance: 'error' });

        setIsLoading(false);

        return;
      }
    }

    const customer = handleCustomerType();

    const request = {
      email: signUpForm.email,
      name: `${signUpForm.first_name} ${signUpForm.last_name}`,
      password: crypto
        .createHash('sha512')
        .update(signUpForm.password)
        .digest('hex'),
      job: customer.text,
      country: isCountry ? 'guam' : 'saipan',
      mobile: signUpForm.mobile,
      address: signUpForm.business_name,
      detail_address: signUpForm.etc,
      system4u_purchase:
        isPurchased === 'YES' ? true : isPurchased === 'NO' ? false : null,
      customer_type: customer.type,
    };

    dispatch(handleUserRequest(request, success, failure));
  };

  useEffect(() => {
    setIsPurchased('none');
    setLicensedStudent(false);
    setSalonBusiness(false);
    setOther(true);
    setIsCountry(true);
    setImage([]);
    resetSignIn(signInInitialForm);
    resetSignUp(signUpInitialForm);
  }, [isSign]);

  return (
    <Component
      isLoading={isLoading}
      isSign={isSign}
      handleIsSign={handleIsSign}
      isPurchased={isPurchased}
      setIsPurchased={setIsPurchased}
      licensedStudent={licensedStudent}
      setLicensedStudent={setLicensedStudent}
      salonBusiness={salonBusiness}
      setSalonBusiness={setSalonBusiness}
      other={other}
      setOther={setOther}
      isCountry={isCountry}
      setIsCountry={setIsCountry}
      image={image}
      setImage={setImage}
      signInForm={signInForm}
      onChangeSignIn={onChangeSignIn}
      signUpForm={signUpForm}
      onChangeSignUp={onChangeSignUp}
      handleSignIn={handleSignIn}
      handleSignUp={handleSignUp}
    />
  );
}
