import { createAction } from 'redux-actions';

import { Payload } from '../reducer/product.reducer';

export const SEARCH_REQUEST = 'product/SEARCH_REQUEST';
export const SEARCH_SUCCESS = 'product/SEARCH_SUCCESS';
export const SEARCH_FAILURE = 'product/SEARCH_FAILURE';
export const handleSearchRequest = createAction(
  SEARCH_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const CATEGORY_MENU_REQUEST = 'product/CATEGORY_MENU_REQUEST';
export const CATEGORY_MENU_SUCCESS = 'product/CATEGORY_MENU_SUCCESS';
export const CATEGORY_MENU_FAILURE = 'product/CATEGORY_MENU_FAILURE';
export const handleCategoryMenuRequest = createAction(
  CATEGORY_MENU_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const CATEGORY_SUB_MENU_REQUEST = 'product/CATEGORY_SUB_MENU_REQUEST';
export const CATEGORY_SUB_MENU_SUCCESS = 'product/CATEGORY_SUB_MENU_SUCCESS';
export const CATEGORY_SUB_MENU_FAILURE = 'product/CATEGORY_SUB_MENU_FAILURE';
export const handleCategorySubMenuRequest = createAction(
  CATEGORY_SUB_MENU_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const FAVORITE_PRODUCT_REQUEST = 'product/FAVORITE_PRODUCT_REQUEST';
export const FAVORITE_PRODUCT_SUCCESS = 'product/FAVORITE_PRODUCT_SUCCESS';
export const FAVORITE_PRODUCT_FAILURE = 'product/FAVORITE_PRODUCT_FAILURE';
export const handleFavoriteProductRequest = createAction(
  FAVORITE_PRODUCT_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const MY_FAVORITE_PRODUCTS_REQUEST =
  'product/MY_FAVORITE_PRODUCTS_REQUEST';
export const MY_FAVORITE_PRODUCTS_SUCCESS =
  'product/MY_FAVORITE_PRODUCTS_SUCCESS';
export const MY_FAVORITE_PRODUCTS_FAILURE =
  'product/MY_FAVORITE_PRODUCTS_FAILURE';
export const handleMyFavoriteProductsRequest = createAction(
  MY_FAVORITE_PRODUCTS_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const PRODUCT_LIST_REQUEST = 'product/PRODUCT_LIST_REQUEST';
export const PRODUCT_LIST_SUCCESS = 'product/PRODUCT_LIST_SUCCESS';
export const PRODUCT_LIST_1_SUCCESS = 'product/PRODUCT_LIST_1_SUCCESS';
export const PRODUCT_LIST_2_SUCCESS = 'product/PRODUCT_LIST_2_SUCCESS';
export const PRODUCT_LIST_3_SUCCESS = 'product/PRODUCT_LIST_3_SUCCESS';
export const PRODUCT_LIST_4_SUCCESS = 'product/PRODUCT_LIST_4_SUCCESS';
export const PRODUCT_LIST_FAILURE = 'product/PRODUCT_LIST_FAILURE';
export const handleProductListRequest = createAction(
  PRODUCT_LIST_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const BRAND_LIST_REQUEST = 'product/BRAND_LIST_REQUEST';
export const BRAND_LIST_SUCCESS = 'product/BRAND_LIST_SUCCESS';
export const BRAND_LIST_FAILURE = 'product/BRAND_LIST_FAILURE';
export const handleBrandListRequest = createAction(
  BRAND_LIST_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const PRODUCT_DETAIL_REQUEST = 'product/PRODUCT_DETAIL_REQUEST';
export const PRODUCT_DETAIL_SUCCESS = 'product/PRODUCT_DETAIL_SUCCESS';
export const PRODUCT_DETAIL_FAILURE = 'product/PRODUCT_DETAIL_FAILURE';
export const handleProductDetailRequest = createAction(
  PRODUCT_DETAIL_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const PURCHASE_REQUEST = 'product/PURCHASE_REQUEST';
export const PURCHASE_SUCCESS = 'product/PURCHASE_SUCCESS';
export const PURCHASE_FAILURE = 'product/PURCHASE_FAILURE';
export const handlePurchaseRequest = createAction(
  PURCHASE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const PURCHASE_LIST_REQUEST = 'product/PURCHASE_LIST_REQUEST';
export const PURCHASE_LIST_SUCCESS = 'product/PURCHASE_LIST_SUCCESS';
export const PURCHASE_LIST_FAILURE = 'product/PURCHASE_LIST_FAILURE';
export const handlePurchaseListRequest = createAction(
  PURCHASE_LIST_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const PURCHASE_UPDATE_REQUEST = 'product/PURCHASE_UPDATE_REQUEST';
export const PURCHASE_UPDATE_SUCCESS = 'product/PURCHASE_UPDATE_SUCCESS';
export const PURCHASE_UPDATE_FAILURE = 'product/PURCHASE_UPDATE_FAILURE';
export const handlePurchaseUpdateRequest = createAction(
  PURCHASE_UPDATE_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);

export const PURCHASE_DETAIL_REQUEST = 'product/PURCHASE_DETAIL_REQUEST';
export const PURCHASE_DETAIL_SUCCESS = 'product/PURCHASE_DETAIL_SUCCESS';
export const PURCHASE_DETAIL_FAILURE = 'product/PURCHASE_DETAIL_FAILURE';
export const handlePurchaseDetailRequest = createAction(
  PURCHASE_DETAIL_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const BANNER_LIST_REQUEST = 'product/BANNER_LIST_REQUEST';
export const BANNER_LIST_SUCCESS = 'product/BANNER_LIST_SUCCESS';
export const BANNER_LIST_FAILURE = 'product/BANNER_LIST_FAILURE';
export const handleBannerListRequest = createAction(
  BANNER_LIST_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const NOTICE_REQUEST = 'product/NOTICE_REQUEST';
export const NOTICE_SUCCESS = 'product/NOTICE_SUCCESS';
export const NOTICE_FAILURE = 'product/NOTICE_FAILURE';
export const handleNoticeRequest = createAction(
  NOTICE_REQUEST,
  (success: Payload['success'], failure: Payload['failure']) => ({
    success,
    failure,
  })
);

export const INQUIRY_REQUEST = 'product/INQUIRY_REQUEST';
export const INQUIRY_SUCCESS = 'product/INQUIRY_SUCCESS';
export const INQUIRY_FAILURE = 'product/INQUIRY_FAILURE';
export const handleInquiryRequest = createAction(
  INQUIRY_REQUEST,
  (
    data: Payload['data'],
    success: Payload['success'],
    failure: Payload['failure']
  ) => ({
    data,
    success,
    failure,
  })
);
