import { ReactChild, useState } from 'react';
import styled, { CSSProperties } from 'styled-components';
import cn from 'classnames';

export type CheckBoxProps = {
  onClick: (props: boolean) => void;
  style?: CSSProperties;
  contentsStyle?: CSSProperties;
  className?: string;
  children: ReactChild | ReactChild[];
};

export default function CheckBox({
  onClick,
  style,
  contentsStyle,
  className,
  children,
}: CheckBoxProps) {
  const [isCheckBox, setIsCheckBox] = useState(false);

  const handleClick = () => {
    if (isCheckBox) {
      onClick(false);

      setIsCheckBox(false);
    } else {
      onClick(true);

      setIsCheckBox(true);
    }
  };

  return (
    <Block style={style} className={cn([className])} onClick={handleClick}>
      <Input type='checkbox' checked={isCheckBox} readOnly />

      <Contents style={contentsStyle}>{children}</Contents>
    </Block>
  );
}

const Block = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  user-select: none;
  cursor: pointer;
`;

const Input = styled.input`
  width: 1.3rem;
  height: 1.3rem;
  margin-left: 0;
  margin-right: 1rem;
  cursor: pointer;
`;

const Contents = styled.span`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 300;
  color: ${(prop) => prop.theme.primary};
`;
