import styled from 'styled-components';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import tokenLib from '../../library/token.lib';

type ProductsProps = {
  product_list: any[];
  search: string;
  setSearch: (state: string) => void;
};

export default function Products({
  product_list,
  search,
  setSearch,
}: ProductsProps) {
  const history = useHistory();

  const handlePush = (path: string) => {
    history.push(path);
  };

  const handleType = (type: string) => {
    switch (type) {
      case 'new':
        return 'New';

      case 'best':
        return 'Best';

      case 'sale':
        return 'Sale';

      default:
        break;
    }
  };

  return (
    <Box>
      <input
        className={cn('input')}
        placeholder='Product and brand search'
        value={search}
        onChange={(value) => setSearch(value.currentTarget.value)}
      />

      <div className={cn('list')}>
        {product_list.map((data: any, index) => {
          if (!data.image_list.length) return <></>;

          return (
            <div
              key={index}
              className={cn('list-item')}
              onClick={() => handlePush(`/mobile/product/${data.id}`)}
            >
              {data.type === 'none' ? (
                <></>
              ) : (
                <span className={cn(`list-item-${data.type}`)}>
                  {handleType(data.type)}
                </span>
              )}

              <img
                className={cn('list-item-img')}
                src={data.image_list[0].image}
                alt='item_img'
              />

              <span className={cn('list-item-brand')}>{data.brand.name}</span>

              <span className={cn('list-item-product')}>{data.name}</span>

              <span
                className={cn('list-item-notice')}
                onClick={() => {
                  if (!tokenLib.getRefreshToken()) handlePush('/mobile/sign');
                }}
              >
                {!tokenLib.getRefreshToken()
                  ? 'Login or Register to buy'
                  : tokenLib.getCountry() === 'guam'
                  ? `$${Number(data.guam_original_price).toLocaleString()}`
                  : `$${Number(data.saipan_original_price).toLocaleString()}`}
              </span>
            </div>
          );
        })}
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 15px;

  .input {
    width: 100%;
    height: 50px;
    border: 1px solid #acacac;
    padding: 15px;
    margin-bottom: 30px;
    outline: none;

    &::placeholder {
      color: #acacac;
    }
  }

  .list {
    width: 100%;
    height: fit-content;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(auto-fill, 280px);
    row-gap: 30px;
    column-gap: 20px;

    &-item {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 20px;
      border: 1px solid #dbdbdb;

      &-best,
      &-new,
      &-sale {
        width: fit-content;
        height: fit-content;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 3px 8px;
        border-radius: 5px;
        font-size: 8px;
        font-weight: 500;
        color: #fff;
      }

      &-best {
        background-color: #88c764;
      }

      &-new {
        background-color: #f68b50;
      }

      &-sale {
        background-color: #f15a53;
      }

      &-img {
        width: 100%;
        height: 130px;
        object-fit: contain;
        object-position: center;
        margin-bottom: 20px;
        cursor: pointer;
      }

      &-brand {
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 10px;
        cursor: pointer;
        user-select: none;
        font-family: 'Merriweather';
      }

      &-product {
        width: 100%;
        max-width: 110px;
        font-size: 16px;
        margin-bottom: 10px;
        cursor: pointer;
        user-select: none;
        font-family: 'Oswald';
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &-notice {
        font-size: 16px;
        font-weight: 600;
        font-style: italic;
      }
    }
  }
`;
