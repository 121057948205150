import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { DefaultTheme, ThemeProvider } from 'styled-components';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from '@redux-saga/core';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import logger from 'redux-logger';
import { ToastProvider } from 'react-toast-notifications';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

import * as Page from './pages';

import rootReducer from './redux/reducer';
import rootSaga from './redux/saga';

import GlobalStyle from './style/globalStyle';
import theme from './constants/theme';

const sagaMiddleware = createSagaMiddleware();

const enhancer =
  process.env.REACT_APP_ENVIRONMENT === 'development'
    ? composeWithDevTools(applyMiddleware(logger, sagaMiddleware))
    : compose(applyMiddleware(sagaMiddleware));

const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

const routes = [
  {
    path: '/',
    exact: true,
    main: () => <Page.Main />,
  },
  {
    path: '/products/:id',
    exact: true,
    main: () => <Page.Products />,
  },
  {
    path: '/product/:id',
    exact: true,
    main: () => <Page.Product />,
  },
  {
    path: '/sign',
    exact: true,
    main: () => <Page.Sign />,
  },
  {
    path: '/purchase',
    exact: true,
    main: () => <Page.Purchase />,
  },
  {
    path: '/order-history',
    exact: true,
    main: () => <Page.OrderHistory />,
  },
  {
    path: '/favorite',
    exact: true,
    main: () => <Page.Favorite />,
  },
  {
    path: '/profile',
    exact: true,
    main: () => <Page.Profile />,
  },
  {
    path: '/shopbybrand',
    exact: true,
    main: () => <Page.ShopByBrand />,
  },

  {
    path: '/mobile/products',
    exact: true,
    main: () => <Page.MobileProducts />,
  },
  {
    path: '/mobile/products/:id',
    exact: true,
    main: () => <Page.MobileProducts />,
  },
  {
    path: '/mobile/product/:id',
    exact: true,
    main: () => <Page.MobileProduct />,
  },
  {
    path: '/mobile',
    exact: true,
    main: () => <Page.MobileMain />,
  },
  {
    path: '/mobile/sign',
    exact: true,
    main: () => <Page.MobileSign />,
  },
  {
    path: '/mobile/order-history',
    exact: true,
    main: () => <Page.MobileOrderHistory />,
  },
  {
    path: '/mobile/shopbybrand',
    exact: true,
    main: () => <Page.MobileShopByBrand />,
  },
  {
    path: '/mobile/profile',
    exact: true,
    main: () => <Page.MobileProfile />,
  },
  {
    path: '/mobile/purchase',
    exact: true,
    main: () => <Page.MobilePurchase />,
  },
  {
    path: '/mobile/favorite',
    exact: true,
    main: () => <Page.MobileFavorite />,
  },
];

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Provider store={store}>
      <PayPalScriptProvider
        options={{
          clientId: String(process.env.REACT_APP_PAYPAL_CLIENT_ID),
        }}
      >
        <ToastProvider autoDismiss={true} autoDismissTimeout={3000}>
          <ThemeProvider theme={theme as DefaultTheme}>
            <GlobalStyle />
            <Router>
              <ScrollToTop />
              <Switch>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    children={<route.main />}
                  />
                ))}
              </Switch>
            </Router>
          </ThemeProvider>
        </ToastProvider>
      </PayPalScriptProvider>
    </Provider>
  );
}

export default App;
