import { createGlobalStyle } from 'styled-components';
import theme from '../constants/theme';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing:border-box;
    font-size: 16px;
    color:${theme.black};
    font-family: 'Exo 2';
    font-weight: 400;
    line-height: 1.3;
  }

  html, body {
    width: 100%;
    height: 100%;
  }

  #root{
    width: 100%;
    height: 100%;
  }
`;

export default GlobalStyle;
