import { useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { ArrowForwardIos } from '@mui/icons-material';
import Color from 'color';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';

export default function Banner() {
  const { banner_list } = useSelector(({ product }: RootState) => product);

  const [index, setIndex] = useState<number>(0);

  return (
    <Box>
      <div
        className={cn('icon_wrap')}
        onClick={() => {
          if (index === 0) return;

          setIndex(index - 1);
        }}
      >
        <ArrowForwardIos
          className={cn('icon_wrap-icon')}
          style={{ transform: 'rotate(180deg)' }}
        />
      </div>

      <div
        className={cn('icon_wrap')}
        style={{ right: 0 }}
        onClick={() => {
          if (index === banner_list.length - 1) return;

          setIndex(index + 1);
        }}
      >
        <ArrowForwardIos className={cn('icon_wrap-icon')} />
      </div>

      <div className={cn('bullet_wrap')}>
        {banner_list.map((_data, indexB) => {
          return (
            <div
              key={indexB}
              className={cn('bullet_wrap-bullet', {
                'bullet_wrap-select': index === indexB,
              })}
              onClick={() => {
                setIndex(indexB);
              }}
            />
          );
        })}
      </div>

      <img
        className={cn('banner_img')}
        src={banner_list[index] ? banner_list[index].image : ''}
        alt='banner'
        draggable={false}
      />
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;

  .banner_img {
    width: 100%;
    height: auto;
    object-fit: cover;
    object-position: center;
  }

  .icon_wrap {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${(prop) =>
      Color(prop.theme.black).alpha(0.5).hsl().toString()};
    cursor: pointer;

    &-icon {
      width: 60%;
      height: 60%;
      display: flex;
      justify-content: center;
      fill: ${(prop) => prop.theme.white};
    }
  }

  .bullet_wrap {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: row;
    position: absolute;
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);

    &-bullet {
      width: 16px;
      height: 16px;
      border: 1px solid ${(prop) => prop.theme.primary};
      border-radius: 50%;
      background-color: ${(prop) => prop.theme.white};
      margin-right: 20px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }

    &-select {
      background-color: ${(prop) => prop.theme.primary};
    }
  }
`;
