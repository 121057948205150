import styled from 'styled-components';
import cn from 'classnames';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useState } from 'react';

import dayjs from 'dayjs';
import {
  handlePurchaseListRequest,
  handlePurchaseUpdateRequest,
} from '../../redux/action/product.action';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

function Card({ purchase }: { purchase: any }) {
  const { addToast } = useToasts();

  const dispatch = useDispatch();

  const [isMore, setIsMore] = useState(false);

  const handleCancel = () => {
    function successPurchase() {
      addToast('Order has been canceled.', { appearance: 'success' });

      function success() {}
      function failure() {}

      dispatch(handlePurchaseListRequest(success, failure));
    }

    function failurePurchase() {}

    dispatch(
      handlePurchaseUpdateRequest(
        {
          id: purchase.id,
          status: 'cancel',
        },
        successPurchase,
        failurePurchase
      )
    );
  };

  return (
    <div className={cn('card')}>
      <div className={cn('card-header')}>
        <span className={cn('card-header-date')}>
          {dayjs(purchase.createdAt).format('YYYY.MM.DD')}
        </span>

        <span
          className={cn('card-header-more')}
          onClick={() => setIsMore(!isMore)}
        >
          More{' '}
          {!isMore ? (
            <KeyboardArrowUp className={cn('card-header-more-icon')} />
          ) : (
            <KeyboardArrowDown className={cn('card-header-more-icon')} />
          )}
        </span>
      </div>

      <div className={cn('card-contents')}>
        <div className={cn('card-contents-products')}>
          {purchase.detail_list.map((data: any, index: number) => {
            if (!isMore && index) return <></>;

            const product = purchase.product_list.find(
              (p: any) => p.id === data.product_id
            );

            if (!product) return <></>;

            return (
              <div key={index} className={cn('card-contents-products-item')}>
                <img
                  className={cn('card-contents-products-item-img')}
                  src={product.image_list[0].image}
                  alt='item_img'
                />

                <div className={cn('card-contents-products-item-wrap')}>
                  <span
                    className={cn('card-contents-products-item-wrap-product')}
                  >
                    {product.name}
                  </span>

                  <span
                    className={cn('card-contents-products-item-wrap-brand')}
                  >
                    {product.brand.name}
                  </span>

                  <span className={cn('card-contents-products-item-wrap-sq')}>
                    Q {data.count}/
                    {data.measurement === 'ml'
                      ? `${data.size} ml`
                      : `${data.size} fl oz`}
                    {product.product_type === 'default'
                      ? ''
                      : `/${
                          product.color_list.filter(
                            (c: any) => String(c.id) === data.color_id
                          )[0].name
                        } (${
                          product.color_list.filter(
                            (c: any) => String(c.id) === data.color_id
                          )[0].code
                        })`}
                  </span>
                </div>

                <div className={cn('card-contents-products-item-wrap2')}>
                  <span
                    className={cn('card-contents-products-item-wrap2-price')}
                  >
                    ${parseFloat(data.total_price).toLocaleString()}
                  </span>

                  <span
                    className={cn('card-contents-products-item-wrap2-date')}
                  >
                    {dayjs(purchase.createdAt).format('YYYY.MM.DD')}
                  </span>
                </div>
              </div>
            );
          })}
        </div>

        {isMore ? (
          <div className={cn('card-contents-details')}>
            <div className={cn('card-contents-details-info')}>
              <span className={cn('card-contents-details-info-title')}>
                Full name (First and Last name)
              </span>

              <span className={cn('card-contents-details-info-text')}>
                {purchase.user.name}
              </span>

              <span className={cn('card-contents-details-info-title')}>
                Delivery location
              </span>

              <span className={cn('card-contents-details-info-text')}>
                {`${purchase.user.address_list[0].address} ${purchase.user.address_list[0].detail_address}`}
              </span>

              <span className={cn('card-contents-details-info-title')}>
                Phone number
              </span>

              <span className={cn('card-contents-details-info-text')}>
                {purchase.user.mobile}
              </span>
            </div>

            <div className={cn('card-contents-details-bottom')}>
              <div className={cn('card-contents-details-bottom-contact')}>
                <span
                  className={cn('card-contents-details-bottom-contact-text')}
                >
                  Please contact us at
                </span>

                <div
                  className={cn('card-contents-details-bottom-contact-wrap')}
                >
                  <span
                    className={cn(
                      'card-contents-details-bottom-contact-wrap-text'
                    )}
                  >
                    +1 671-646-4909
                  </span>

                  <span
                    className={cn(
                      'card-contents-details-bottom-contact-wrap-or'
                    )}
                  >
                    OR
                  </span>

                  <span
                    className={cn(
                      'card-contents-details-bottom-contact-wrap-text'
                    )}
                  >
                    system4uguam@gmail.com
                  </span>
                </div>
              </div>

              {purchase.status === 'pending' ? (
                <button
                  className={cn('card-contents-details-bottom-btn')}
                  onClick={handleCancel}
                >
                  Cancel Order
                </button>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

type MobileOrderHistoryProps = {
  purchase_list: any[];
};

export default function MobileOrderHistory({
  purchase_list,
}: MobileOrderHistoryProps) {
  return (
    <Box>
      <span className={cn('title')}>Order History</span>

      {purchase_list.map((data, index) => {
        return <Card key={index} purchase={data} />;
      })}
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 30px;
    font-weight: 500;
    margin: 30px 10px;
  }

  .card {
    width: 100%;
    height: fit-content;
    padding: 30px;
    border-radius: 5px;
    background-color: #f0f6ec;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    &-header {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 25px;

      &-date {
        font-size: 20px;
        font-weight: 600;
      }

      &-more {
        width: fit-content;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 500;
        text-decoration: underline;
        user-select: none;
        cursor: pointer;

        &-icon {
          width: 20px;
          height: auto;
        }
      }
    }

    &-contents {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: column;

      &-products {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        &-item {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding: 30px;
          margin-bottom: 24px;
          background-color: #fff;

          &:last-child {
            margin-bottom: 0;
          }

          &-img {
            width: 100px;
            height: 100px;
            object-fit: contain;
            object-position: center;
            border: 1px solid #828282;
            padding: 20px;
            margin-right: 20px;
          }

          &-wrap {
            width: calc(100% - 120px);
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;

            &-product {
              width: fit-content;
              max-width: 130px;
              font-size: 16px;
              font-weight: 500;
              margin-bottom: 10px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &-brand {
              font-size: 16px;
              margin-bottom: 10px;
              color: #828282;
            }

            &-sq {
              font-size: 14px;
              color: #828282;
            }
          }

          &-wrap2 {
            width: 100%;
            height: fit-content;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            margin-left: auto;
            margin-top: 20px;

            &-price {
              font-size: 20px;
              font-weight: 600;
              color: #333;
              margin-bottom: 10px;
            }

            &-date {
              font-size: 14px;
              color: #828282;
            }
          }
        }
      }

      &-details {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;

        &-info {
          width: 100%;
          height: fit-content;
          display: flex;
          flex-direction: column;
          padding: 20px 30px;
          background-color: #fff;
          margin-top: 20px;

          &-title {
            width: fit-content;
            height: fit-content;
            font-size: 14px;
            font-weight: 500;
            color: #adb5bd;
            margin-bottom: 13px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          &-text {
            width: fit-content;
            height: fit-content;
            font-size: 14px;
            font-weight: 500;
            color: #292929;
            margin-bottom: 13px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &-bottom {
          width: 100%;
          height: fit-content;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          margin-top: 20px;

          &-contact {
            width: fit-content;
            height: fit-content;
            display: flex;
            flex-direction: column;

            &-text {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 5px;
            }

            &-wrap {
              width: fit-content;
              height: fit-content;
              display: flex;
              flex-direction: column;

              &-text {
                font-size: 12px;
                font-weight: 600;
              }

              &-or {
                font-size: 12px;
                font-weight: 600;
                color: #adb5bd;
              }
            }
          }

          &-btn {
            width: 100%;
            height: fit-content;
            padding: 14px 38px;
            border: 2px solid #8f8f8f;
            border-radius: 5px;
            background-color: #000;
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            margin-top: 20px;
          }
        }
      }
    }
  }
`;
