import { useParams } from 'react-router-dom';
import Component from '../../components/mobile_products';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  handleCategorySubMenuRequest,
  handleProductListRequest,
} from '../../redux/action/product.action';
import { RootState } from '../../redux/reducer';

export default function Products() {
  const { id } = useParams<{ id: string }>();

  const dispath = useDispatch();
  const { product_list } = useSelector(({ product }: RootState) => product);

  const [search, setSearch] = useState('');

  useEffect(() => {
    function success() {}
    function failure() {}

    dispath(
      handleProductListRequest(
        {
          id,
          category: 0,
          search: search.replaceAll(' ', ''),
          page: 0,
          limit: 0,
        },
        success,
        failure
      )
    );

    dispath(handleCategorySubMenuRequest(success, failure));
  }, [id, search]);

  return (
    <Component
      product_list={product_list}
      search={search}
      setSearch={setSearch}
    />
  );
}
