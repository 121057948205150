import styled from 'styled-components';
import cn from 'classnames';
import { ArrowForwardIos } from '@mui/icons-material';
import Color from 'color';
import { useHistory } from 'react-router-dom';

import Banner from '../../containers/main/Banner';

import BRAND_1 from '../../static/images/brand_01.png';
import BRAND_2 from '../../static/images/brand_02.png';
import BRAND_3 from '../../static/images/brand_03.png';
import BRAND_4 from '../../static/images/brand_04.png';
import BRAND_5 from '../../static/images/brand_05.png';
import BRAND_6 from '../../static/images/brand_06.png';
import BRAND_7 from '../../static/images/brand_07.png';
import BRAND_8 from '../../static/images/brand_08.png';
import BRAND_9 from '../../static/images/brand_09.png';
import BRAND_10 from '../../static/images/brand_10.png';
import BRAND_11 from '../../static/images/brand_11.png';
import BRAND_12 from '../../static/images/brand_12.png';
import BRAND_13 from '../../static/images/brand_13.png';
import BRAND_14 from '../../static/images/brand_14.png';
import tokenLib from '../../library/token.lib';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducer';

type MainProps = {
  product_list_1: any[];
  product_list_2: any[];
  product_list_3: any[];
  product_list_4: any[];
  index1: number;
  setIndex1: (state: number) => void;
  index2: number;
  setIndex2: (state: number) => void;
  index3: number;
  setIndex3: (state: number) => void;
  index4: number;
  setIndex4: (state: number) => void;
};

export default function Main({
  product_list_1,
  product_list_2,
  product_list_3,
  product_list_4,
  index1,
  index2,
  index3,
  index4,
  setIndex1,
  setIndex2,
  setIndex3,
  setIndex4,
}: MainProps) {
  const history = useHistory();

  const { totalPage_1, totalPage_2, totalPage_3, totalPage_4 } = useSelector(
    ({ product }: RootState) => product
  );

  const handleType = (type: string) => {
    switch (type) {
      case 'new':
        return 'New';

      case 'best':
        return 'Best';

      case 'sale':
        return 'Sale';

      default:
        break;
    }
  };

  return (
    <Box>
      <Banner />

      <div className={cn('template_list')}>
        <div className={cn('template_list-wrap')}>
          <span className={cn('template_list-wrap-title')}>
            Deals & Hot Offers
          </span>

          <span
            className={cn('template_list-wrap-subtitle')}
            onClick={() =>
              history.push(
                `/products/${
                  process.env.REACT_APP_ENVIRONMENT === 'development' ? 2 : 16
                }`
              )
            }
          >
            View All Products
          </span>
        </div>

        <div className={cn('template_list-content')}>
          <div
            className={cn('icon_wrap')}
            style={{ left: '50px' }}
            onClick={() => {
              if (index1 === 1) return;

              setIndex1(index1 - 1);
            }}
          >
            <ArrowForwardIos
              className={cn('icon_wrap-icon')}
              style={{ transform: 'rotate(180deg)' }}
            />
          </div>

          <div
            className={cn('icon_wrap')}
            style={{ right: '50px' }}
            onClick={() => {
              if (index1 === totalPage_1) return;

              setIndex1(index1 + 1);
            }}
          >
            <ArrowForwardIos className={cn('icon_wrap-icon')} />
          </div>

          <div className={cn('bullet_wrap')}>
            {new Array(totalPage_1).fill(0).map((_data, index) => {
              return (
                <div
                  key={index}
                  className={cn('bullet_wrap-bullet', {
                    'bullet_wrap-select': index1 === index + 1,
                  })}
                  onClick={() => {
                    setIndex1(index + 1);
                  }}
                />
              );
            })}
          </div>

          <div
            className={cn('list')}
            style={{
              justifyContent:
                product_list_1.length !== 3 ? 'flex-start' : 'space-between',
            }}
          >
            {product_list_1.map((data, index) => {
              if (!data.image_list.length) return <></>;

              return (
                <div
                  key={index}
                  className={cn('list-item')}
                  onClick={() => history.push(`/product/${data.id}`)}
                >
                  {data.type === 'none' ? (
                    <></>
                  ) : (
                    <span className={cn(`list-item-${data.type}`)}>
                      {handleType(data.type)}
                    </span>
                  )}

                  <img
                    className={cn('list-item-img')}
                    src={data.image_list[0].image}
                    alt='item_img'
                  />

                  <div className={cn('list-item-text_wrap')}>
                    <span className={cn('list-item-text_wrap-title')}>
                      {data.brand.name}
                    </span>

                    <div className={cn('list-item-text_wrap-details')}>
                      {data.name}
                    </div>

                    <div className={cn('list-item-text_wrap-notice_1')}>
                      {data.red}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={cn('template_list')}>
        <div className={cn('template_list-wrap')}>
          <span className={cn('template_list-wrap-title')}>New Arrival</span>

          <span
            className={cn('template_list-wrap-subtitle')}
            onClick={() =>
              history.push(
                `/products/${
                  process.env.REACT_APP_ENVIRONMENT === 'development' ? 2 : 17
                }`
              )
            }
          >
            View All Products
          </span>
        </div>

        <div className={cn('template_list-content')}>
          <div
            className={cn('icon_wrap')}
            style={{ left: '50px' }}
            onClick={() => {
              if (index2 === 1) return;

              setIndex2(index2 - 1);
            }}
          >
            <ArrowForwardIos
              className={cn('icon_wrap-icon')}
              style={{ transform: 'rotate(180deg)' }}
            />
          </div>

          <div
            className={cn('icon_wrap')}
            style={{ right: '50px' }}
            onClick={() => {
              if (index2 === totalPage_2) return;

              setIndex2(index2 + 1);
            }}
          >
            <ArrowForwardIos className={cn('icon_wrap-icon')} />
          </div>

          <div className={cn('bullet_wrap')}>
            {new Array(totalPage_2).fill(0).map((_data, index) => {
              return (
                <div
                  key={index}
                  className={cn('bullet_wrap-bullet', {
                    'bullet_wrap-select': index2 === index + 1,
                  })}
                  onClick={() => {
                    setIndex2(index + 1);
                  }}
                />
              );
            })}
          </div>

          <div
            className={cn('list')}
            style={{
              justifyContent:
                product_list_2.length !== 3 ? 'flex-start' : 'space-between',
            }}
          >
            {product_list_2.map((data, index) => {
              if (!data.image_list.length) return <></>;

              return (
                <div
                  key={index}
                  className={cn('list-item')}
                  onClick={() => history.push(`/product/${data.id}`)}
                >
                  {data.type === 'none' ? (
                    <></>
                  ) : (
                    <span className={cn(`list-item-${data.type}`)}>
                      {handleType(data.type)}
                    </span>
                  )}

                  <img
                    className={cn('list-item-img')}
                    src={data.image_list[0].image}
                    alt='item_img'
                  />

                  <div className={cn('list-item-text_wrap')}>
                    <span className={cn('list-item-text_wrap-title')}>
                      {data.brand.name}
                    </span>

                    <div className={cn('list-item-text_wrap-details')}>
                      {data.name}
                    </div>

                    <div className={cn('list-item-text_wrap-notice_2')}>
                      {data.orange}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={cn('template_list')}>
        <div className={cn('template_list-wrap')}>
          <span className={cn('template_list-wrap-title')}>Best Sellers</span>

          <span
            className={cn('template_list-wrap-subtitle')}
            onClick={() =>
              history.push(
                `/products/${
                  process.env.REACT_APP_ENVIRONMENT === 'development' ? 2 : 18
                }`
              )
            }
          >
            View All Products
          </span>
        </div>

        <div className={cn('template_list-content')}>
          <div
            className={cn('icon_wrap')}
            style={{ left: '50px' }}
            onClick={() => {
              if (index3 === 1) return;

              setIndex3(index3 - 1);
            }}
          >
            <ArrowForwardIos
              className={cn('icon_wrap-icon')}
              style={{ transform: 'rotate(180deg)' }}
            />
          </div>

          <div
            className={cn('icon_wrap')}
            style={{ right: '50px' }}
            onClick={() => {
              if (index3 === totalPage_3) return;

              setIndex3(index3 + 1);
            }}
          >
            <ArrowForwardIos className={cn('icon_wrap-icon')} />
          </div>

          <div className={cn('bullet_wrap')}>
            {new Array(totalPage_3).fill(0).map((_data, index) => {
              return (
                <div
                  key={index}
                  className={cn('bullet_wrap-bullet', {
                    'bullet_wrap-select': index3 === index + 1,
                  })}
                  onClick={() => {
                    setIndex3(index + 1);
                  }}
                />
              );
            })}
          </div>

          <div
            className={cn('list')}
            style={{
              justifyContent:
                product_list_3.length !== 4 ? 'flex-start' : 'space-between',
            }}
          >
            {product_list_3.map((data, index) => {
              if (!data.image_list.length) return <></>;

              return (
                <div key={index} className={cn('list-item2')}>
                  {data.type === 'none' ? (
                    <></>
                  ) : (
                    <span className={cn(`list-item2-${data.type}`)}>
                      {handleType(data.type)}
                    </span>
                  )}

                  <img
                    className={cn('list-item2-img')}
                    src={data.image_list[0].image}
                    alt='item_img'
                    onClick={() => history.push(`/product/${data.id}`)}
                  />

                  <span
                    className={cn('list-item2-brand')}
                    onClick={() => history.push(`/product/${data.id}`)}
                  >
                    {data.brand.name}
                  </span>

                  <span
                    className={cn('list-item2-product')}
                    onClick={() => history.push(`/product/${data.id}`)}
                  >
                    {data.name}
                  </span>

                  <span
                    className={cn('list-item2-sign')}
                    onClick={() => {
                      if (!tokenLib.getRefreshToken()) history.push('/sign');
                    }}
                  >
                    {!tokenLib.getRefreshToken()
                      ? 'Login or Register to buy'
                      : tokenLib.getCountry() === 'guam'
                      ? `$${Number(data.guam_original_price).toLocaleString()}`
                      : `$${Number(
                          data.saipan_original_price
                        ).toLocaleString()}`}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={cn('template_list')}>
        <div className={cn('template_list-wrap')}>
          <span className={cn('template_list-wrap-title')}>Recommendation</span>

          <span
            className={cn('template_list-wrap-subtitle')}
            onClick={() =>
              history.push(
                `/products/${
                  process.env.REACT_APP_ENVIRONMENT === 'development' ? 2 : 19
                }`
              )
            }
          >
            View All Products
          </span>
        </div>

        <div className={cn('template_list-content')}>
          <div
            className={cn('icon_wrap')}
            style={{ left: '50px' }}
            onClick={() => {
              if (index4 === 1) return;

              setIndex4(index4 - 1);
            }}
          >
            <ArrowForwardIos
              className={cn('icon_wrap-icon')}
              style={{ transform: 'rotate(180deg)' }}
            />
          </div>

          <div
            className={cn('icon_wrap')}
            style={{ right: '50px' }}
            onClick={() => {
              if (index4 === totalPage_4) return;

              setIndex4(index4 + 1);
            }}
          >
            <ArrowForwardIos className={cn('icon_wrap-icon')} />
          </div>

          <div className={cn('bullet_wrap')}>
            {new Array(totalPage_4).fill(0).map((_data, index) => {
              return (
                <div
                  key={index}
                  className={cn('bullet_wrap-bullet', {
                    'bullet_wrap-select': index4 === index + 1,
                  })}
                  onClick={() => {
                    setIndex4(index + 1);
                  }}
                />
              );
            })}
          </div>

          <div
            className={cn('list')}
            style={{
              justifyContent:
                product_list_4.length !== 4 ? 'flex-start' : 'space-between',
            }}
          >
            {product_list_4.map((data, index) => {
              if (!data.image_list.length) return <></>;

              return (
                <div key={index} className={cn('list-item2')}>
                  {data.type === 'none' ? (
                    <></>
                  ) : (
                    <span className={cn(`list-item2-${data.type}`)}>
                      {handleType(data.type)}
                    </span>
                  )}

                  <img
                    className={cn('list-item2-img')}
                    src={data.image_list[0].image}
                    alt='item_img'
                    onClick={() => history.push(`/product/${data.id}`)}
                  />

                  <span
                    className={cn('list-item2-brand')}
                    onClick={() => history.push(`/product/${data.id}`)}
                  >
                    {data.brand.name}
                  </span>

                  <span
                    className={cn('list-item2-product')}
                    onClick={() => history.push(`/product/${data.id}`)}
                  >
                    {data.name}
                  </span>

                  <span
                    className={cn('list-item2-sign')}
                    onClick={() => {
                      if (!tokenLib.getRefreshToken()) history.push('/sign');
                    }}
                  >
                    {!tokenLib.getRefreshToken()
                      ? 'Login or Register to buy'
                      : tokenLib.getCountry() === 'guam'
                      ? `$${Number(data.guam_original_price).toLocaleString()}`
                      : `$${Number(
                          data.saipan_original_price
                        ).toLocaleString()}`}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <div className={cn('brand')}>
        <span className={cn('brand-title')}>Brand We Carry</span>

        <div className={cn('brand-wrap')}>
          <img
            className={cn('brand-wrap-img')}
            src={BRAND_1}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_2}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_3}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_4}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_5}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_6}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_7}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_8}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_9}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_10}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_11}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_12}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_13}
            alt='img'
            draggable={false}
          />

          <img
            className={cn('brand-wrap-img')}
            src={BRAND_14}
            alt='img'
            draggable={false}
          />
        </div>
      </div>
    </Box>
  );
}

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .template_list {
    width: 100%;
    height: fit-content;
    position: relative;
    padding: 100px 150px;

    &:last-child {
      margin-bottom: 50px;
    }

    &-wrap {
      width: fit-content;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-bottom: 30px;

      &-title {
        font-size: 36px;
        font-weight: 500;
        margin-right: 40px;
      }

      &-subtitle {
        font-size: 18px;
        font-weight: 500;
        user-select: none;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &-content {
      width: 100%;
      height: fit-content;

      .icon_wrap {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 55%;
        transform: translateY(-50%);
        background-color: ${(prop) =>
          Color(prop.theme.black).alpha(0.5).hsl().toString()};
        cursor: pointer;

        &-icon {
          width: 60%;
          height: 60%;
          display: flex;
          justify-content: center;
          fill: ${(prop) => prop.theme.white};
        }
      }

      .bullet_wrap {
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: row;
        position: absolute;
        left: 50%;
        bottom: 50px;
        transform: translateX(-50%);

        &-bullet {
          width: 16px;
          height: 16px;
          border: 1px solid ${(prop) => prop.theme.primary};
          border-radius: 50%;
          background-color: ${(prop) => prop.theme.white};
          margin-right: 20px;
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }
        }

        &-select {
          background-color: ${(prop) => prop.theme.primary};
        }
      }

      .list {
        width: 100%;
        height: fit-content;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;

        &-item {
          width: 450px;
          height: 300px;
          position: relative;
          display: flex;
          flex-direction: row;
          padding: 10px;
          margin-right: 100px;
          border: 1px solid ${(prop) => Color(prop.theme.gray).whiten(1).hex()};
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &-best,
          &-new,
          &-sale {
            width: fit-content;
            height: fit-content;
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 3px 8px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
          }

          &-best {
            background-color: #88c764;
          }

          &-new {
            background-color: #f68b50;
          }

          &-sale {
            background-color: #f15a53;
          }

          &-img {
            width: 210px;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }

          &-text_wrap {
            width: calc(100% - 230px);
            height: 100%;
            display: flex;
            flex-direction: column;
            margin-left: 20px;

            &-title {
              font-family: 'Merriweather';
              font-size: 26px;
              font-weight: 600;
              margin-bottom: 10px;
            }

            &-details {
              font-size: 20px;
              font-family: 'Oswald';
            }

            &-notice_1 {
              font-family: 'Lato';
              font-size: 22px;
              font-weight: 600;
              text-decoration: underline;
              color: #fa5252;
              margin-top: auto;
              margin-bottom: 0;
            }

            &-notice_2 {
              font-family: 'Lato';
              font-size: 22px;
              font-weight: 600;
              text-decoration: underline;
              color: #ff922b;
              margin-top: auto;
              margin-bottom: 0;
            }
          }
        }

        &-item2 {
          width: 300px;
          height: 450px;
          position: relative;
          display: flex;
          flex-direction: column;
          padding: 10px;
          margin-right: 80px;
          border: 1px solid ${(prop) => Color(prop.theme.gray).whiten(1).hex()};
          cursor: pointer;

          &:last-child {
            margin-right: 0;
          }

          &-best,
          &-new,
          &-sale {
            width: fit-content;
            height: fit-content;
            position: absolute;
            top: 10px;
            left: 10px;
            padding: 3px 8px;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 500;
            color: #fff;
          }

          &-best {
            background-color: #88c764;
          }

          &-new {
            background-color: #f68b50;
          }

          &-sale {
            background-color: #f15a53;
          }

          &-img {
            width: 100%;
            height: 300px;
            object-fit: cover;
            object-position: center;
          }

          &-brand {
            font-family: 'Merriweather';
            font-size: 16px;
            color: #344b42;
            margin-top: 20px;
            margin-bottom: 10px;
          }

          &-product {
            font-size: 20px;
            color: #000;
            margin-bottom: 10px;
            font-family: 'Oswald';
          }

          &-sign {
            text-align: right;
            font-size: 16px;
            font-weight: 600;
            text-decoration: underline;
            color: #000;
            margin-bottom: auto;
            user-select: none;
            cursor: pointer;
          }
        }
      }
    }
  }

  .brand {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    padding: 100px 150px;

    &-title {
      width: fit-content;
      height: fit-content;
      font-size: 36px;
      font-weight: 600;
      color: #000;
      margin-bottom: 50px;
      user-select: none;
    }

    &-wrap {
      width: 100%;
      height: fit-content;
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(auto-fill, auto);
      row-gap: 50px;
      column-gap: 20px;

      &-img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
