import PageTemplate from '../containers/common/PageTemplate';

import Container from '../containers/mobile_purchase';

export default function MobilePurchase() {
  return (
    <>
      <PageTemplate type={true}>
        <Container />
      </PageTemplate>
    </>
  );
}
