import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { Action } from 'redux-actions';

import { Payload } from '../reducer/product.reducer';

import * as ACTION from '../action/product.action';

import API from '../api/product.api';

function* createPurchase(action: Action<Payload>): Generator<any, void, any> {
  try {
    const { data } = yield call(API.createPurchase, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.PURCHASE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PURCHASE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PURCHASE_FAILURE,
      payload: { data: error },
    });
  }
}

function* readSearch(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.getSearch, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.SEARCH_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.SEARCH_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.SEARCH_FAILURE,
      payload: { data: error },
    });
  }
}

function* readPurchase(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.readPurchase);

    action.payload.success(data);

    yield put({
      type: ACTION.PURCHASE_DETAIL_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PURCHASE_DETAIL_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PURCHASE_DETAIL_FAILURE,
      payload: { data: error },
    });
  }
}

function* readAllPurchase(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.readAllPurchase);

    action.payload.success(data);

    yield put({
      type: ACTION.PURCHASE_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PURCHASE_LIST_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PURCHASE_LIST_FAILURE,
      payload: { data: error },
    });
  }
}

function* updatePurchase(action: Action<Payload>): Generator<any, void, any> {
  try {
    const { data } = yield call(API.updatePurchase, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.PURCHASE_UPDATE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PURCHASE_UPDATE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PURCHASE_UPDATE_FAILURE,
      payload: { data: error },
    });
  }
}

function* favoriteProduct(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.favoriteProduct, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.FAVORITE_PRODUCT_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.FAVORITE_PRODUCT_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.FAVORITE_PRODUCT_FAILURE,
      payload: { data: error },
    });
  }
}

function* readProduct(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.getProduct, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.PRODUCT_DETAIL_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PRODUCT_DETAIL_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PRODUCT_DETAIL_FAILURE,
      payload: { data: error },
    });
  }
}

function* myFavoriteProducts(
  action: Action<Payload>
): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.myFavoriteProducts);

    action.payload.success(data);

    yield put({
      type: ACTION.MY_FAVORITE_PRODUCTS_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.MY_FAVORITE_PRODUCTS_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.MY_FAVORITE_PRODUCTS_FAILURE,
      payload: { data: error },
    });
  }
}

function* readAllProducts(action: Action<Payload>): Generator<any, void, any> {
  try {
    const { data } = yield call(API.getProducts, action.payload.data);

    action.payload.success(data);

    if (!action.payload.data.type) {
      yield put({
        type: ACTION.PRODUCT_LIST_SUCCESS,
        payload: { data: data.data },
      });

      return;
    }

    switch (action.payload.data.type) {
      case 1:
        yield put({
          type: ACTION.PRODUCT_LIST_1_SUCCESS,
          payload: { data },
        });

        break;

      case 2:
        yield put({
          type: ACTION.PRODUCT_LIST_2_SUCCESS,
          payload: { data },
        });

        break;

      case 3:
        yield put({
          type: ACTION.PRODUCT_LIST_3_SUCCESS,
          payload: { data },
        });

        break;

      case 4:
        yield put({
          type: ACTION.PRODUCT_LIST_4_SUCCESS,
          payload: { data },
        });

        break;

      default:
        break;
    }
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.PRODUCT_LIST_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.PRODUCT_LIST_FAILURE,
      payload: { data: error },
    });
  }
}

function* readAllCategoryMenu(
  action: Action<Payload>
): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.getCategoryMenu);

    action.payload.success(data);

    yield put({
      type: ACTION.CATEGORY_MENU_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.CATEGORY_MENU_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.CATEGORY_MENU_FAILURE,
      payload: { data: error },
    });
  }
}

function* readAllCategorySubMenu(
  action: Action<Payload>
): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.getCategorySubMenu);

    action.payload.success(data);

    yield put({
      type: ACTION.CATEGORY_SUB_MENU_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.CATEGORY_SUB_MENU_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.CATEGORY_SUB_MENU_FAILURE,
      payload: { data: error },
    });
  }
}

function* readAllBrand(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.readAllBrand);

    action.payload.success(data);

    yield put({
      type: ACTION.BRAND_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.BRAND_LIST_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.BRAND_LIST_FAILURE,
      payload: { data: error },
    });
  }
}

function* readAllBanner(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.readAllBanner);

    action.payload.success(data);

    yield put({
      type: ACTION.BANNER_LIST_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.BANNER_LIST_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.BANNER_LIST_FAILURE,
      payload: { data: error },
    });
  }
}

function* readNotice(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.readNotice);

    action.payload.success(data);

    yield put({
      type: ACTION.NOTICE_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.NOTICE_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.NOTICE_FAILURE,
      payload: { data: error },
    });
  }
}

function* createInquiry(action: Action<Payload>): Generator<any, void, any> {
  try {
    const {
      data: { data },
    } = yield call(API.createInquiry, action.payload.data);

    action.payload.success(data);

    yield put({
      type: ACTION.INQUIRY_SUCCESS,
      payload: { data },
    });
  } catch (error: any) {
    if (error.response) {
      action.payload.failure(error.response.data);

      yield put({
        type: ACTION.INQUIRY_FAILURE,
        payload: { data: error.response.data },
      });
      return;
    }

    yield put({
      type: ACTION.INQUIRY_FAILURE,
      payload: { data: error },
    });
  }
}

function* watchReadProduct() {
  yield takeLatest(ACTION.PRODUCT_DETAIL_REQUEST, readProduct);
}

function* watchReadAllProducts() {
  yield takeEvery(ACTION.PRODUCT_LIST_REQUEST, readAllProducts);
}

function* watchReadAllCategoryMenu() {
  yield takeLatest(ACTION.CATEGORY_MENU_REQUEST, readAllCategoryMenu);
}

function* watchReadAllCategorySubMenu() {
  yield takeLatest(ACTION.CATEGORY_SUB_MENU_REQUEST, readAllCategorySubMenu);
}

function* watchFavoriteProduct() {
  yield takeLatest(ACTION.FAVORITE_PRODUCT_REQUEST, favoriteProduct);
}

function* watchMyFavoriteProducts() {
  yield takeLatest(ACTION.MY_FAVORITE_PRODUCTS_REQUEST, myFavoriteProducts);
}

function* watchBannerList() {
  yield takeLatest(ACTION.BANNER_LIST_REQUEST, readAllBanner);
}

function* watchNotice() {
  yield takeLatest(ACTION.NOTICE_REQUEST, readNotice);
}

function* watchCreatePurchase() {
  yield takeLatest(ACTION.PURCHASE_REQUEST, createPurchase);
}

function* watchReadPurchase() {
  yield takeLatest(ACTION.PURCHASE_DETAIL_REQUEST, readPurchase);
}

function* watchUpdatePurchase() {
  yield takeLatest(ACTION.PURCHASE_UPDATE_REQUEST, updatePurchase);
}

function* watchReadAllPurchase() {
  yield takeLatest(ACTION.PURCHASE_LIST_REQUEST, readAllPurchase);
}

function* watchReadAllBrand() {
  yield takeLatest(ACTION.BRAND_LIST_REQUEST, readAllBrand);
}

function* watchReadSearch() {
  yield takeLatest(ACTION.SEARCH_REQUEST, readSearch);
}

function* watchCreateInquiry() {
  yield takeLatest(ACTION.INQUIRY_REQUEST, createInquiry);
}

function* productSaga() {
  yield all([
    fork(watchReadAllCategoryMenu),
    fork(watchReadAllProducts),
    fork(watchReadProduct),
    fork(watchFavoriteProduct),
    fork(watchMyFavoriteProducts),
    fork(watchBannerList),
    fork(watchNotice),
    fork(watchReadAllCategorySubMenu),
    fork(watchCreatePurchase),
    fork(watchReadPurchase),
    fork(watchUpdatePurchase),
    fork(watchReadAllPurchase),
    fork(watchReadAllBrand),
    fork(watchReadSearch),
    fork(watchCreateInquiry),
  ]);
}

export default productSaga;
