import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Component from '../../components/mobile_main/Banner';
import { handleBannerListRequest } from '../../redux/action/product.action';

export default function Banner() {
  const dispatch = useDispatch();

  useEffect(() => {
    function success() {}
    function failure() {}

    dispatch(handleBannerListRequest(success, failure));
  }, []);

  return <Component />;
}
