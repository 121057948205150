import { all, call } from 'redux-saga/effects';

import product from './product.saga';
import user from './user.saga';

function* rootSaga() {
  yield all([call(product), call(user)]);
}

export default rootSaga;
